import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import Auth0ApolloProvider from './Auth0ApolloProvider';
import { getConfig } from '@yonomi/util-config';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './components/common/Theme';
import App from './App';
import { initWebTracing } from '@yonomi/util-otel-web';

const providerConfig: Auth0ProviderOptions = {
  domain: getConfig('REACT_APP_DOMAIN_NAME'),
  clientId: getConfig('REACT_APP_AUTH0_CLIENT_ID'),
  authorizationParams: {
    redirect_uri: `${window.location.origin}`,
    audience: getConfig('REACT_APP_AUTH0_AUDIENCE'),
    scope:
      'profile email offline_access read:devGroups write:devGroups read:appClients write:appClients read:federationConfig write:federationConfig read:integrations write:integrations read:webhookConfigs write:webhookConfigs',
  },
  useRefreshTokens: true,
};

type Auth0ProviderProps = PropsWithChildren<Auth0ProviderOptions> & {};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: Auth0ProviderProps) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

// Initialize OpenTelemetry web tracing
initWebTracing();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback {...providerConfig}>
        <Auth0ApolloProvider>
          <ThemeProvider theme={Theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </Auth0ApolloProvider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
