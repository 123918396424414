import { FormControl, FormHelperText } from '@mui/material';
import { StyledTextField } from '../../common/Theme';
import React, { MutableRefObject, useState } from 'react';
import { validateDevGroupName } from '@yonomi/util-validators';
import { useMutation, useQuery } from '@apollo/client';
import { createDevGroupGql, getDevGroupGql } from '../../../helpers/gqlQueries';
import { trimObjectValues } from '../../../helpers/dataUtils';
import SubmitButtonWithLoader from '../../common/SubmitButtonWithLoader';

const CREATE_DEV_GROUP = createDevGroupGql();
const GET_DEV_GROUP = getDevGroupGql();

interface CreateDevGroupFormData {
  name: string;
}

interface CreateDevResourceFormProps {
  onCompleted?: MutableRefObject<(data: any) => void>;
  onError: (hasError: boolean, message: string) => void;
}

const CreateDevResourceForm = (props: CreateDevResourceFormProps) => {
  //GQL
  const [createDevGroup, { error: createDevGroupError, loading: creatingDevGroup }] = useMutation(CREATE_DEV_GROUP);

  const { refetch } = useQuery(GET_DEV_GROUP, {
    skip: true,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  //States
  const [formData, setFormData] = useState<CreateDevGroupFormData>({ name: '' });
  const [errors, setErrors] = useState<Record<keyof CreateDevGroupFormData, string>>({ name: '' });
  const [processingDevGroup, setProcessingDevGroup] = useState(false);

  //Handlers
  const validateForm = (data: CreateDevGroupFormData) => {
    let valid = true;
    const newErrors: Record<keyof CreateDevGroupFormData, string> = { name: '' };

    try {
      validateDevGroupName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Dev Resource Group Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({ name: '' });
    setErrors({ name: '' });
  };

  //TODO: Remove this function when permanent event-driven solution is implemented.
  const pollDevGroupForFederationActive = (data: any) => {
    const POLLING_INTERVAL = 1000;
    const POLLING_TIMEOUT = 6000;

    const devGroupId = data.createDevGroup.devGroupId;

    const poll = async () => {
      const result = await refetch({ devGroupId });
      if (result.data.getDevGroup?.federationManagementConfig?.isActive) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        resetForm();
        props.onCompleted?.current(data);
      }
    };

    const intervalId = setInterval(poll, POLLING_INTERVAL);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setProcessingDevGroup(false);
      resetForm();
      props.onError(
        true,
        'Unable to validate Federation Configuration. Please check again later or contact your Yonomi representative.'
      );
      props.onCompleted?.current(data);
    }, POLLING_TIMEOUT);
  };

  const handleCompleted = (data: any) => {
    setProcessingDevGroup(true);
    pollDevGroupForFederationActive(data);
    // TODO: Uncomment this when permanent event-driven solution is implemented.
    // resetForm();
    // props.onCompleted?.current(data);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await createDevGroup({
          variables: { name: trimmedData.name },
          onCompleted: (data) => handleCompleted(data),
        });
      } catch (error) {
        let errorMessage = `Failed to create ${formData.name} Dev Resource Group`;
        if (createDevGroupError) {
          errorMessage = `${errorMessage}: ${createDevGroupError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  return (
    <form id={'form-create-dev-group'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          autoComplete="off"
          id="input-create-dev-group-name"
          label="Dev Group Name"
          name="name"
          type="text"
          onChange={(event) => {
            setErrors({ ...errors, name: '' });
            handleInputChange(event);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}
        <SubmitButtonWithLoader
          id={'btn-create-dev-group-submit'}
          disabled={formData.name.length === 0 || creatingDevGroup || processingDevGroup}
          label={'Create Dev Group'}
          loading={creatingDevGroup || processingDevGroup}
        />
      </FormControl>
    </form>
  );
};

export default CreateDevResourceForm;
