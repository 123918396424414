import React, { ComponentType } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import DevResources from './components/devResources/DevResources';
import AppClients from './components/devResources/appClients/AppClients';
import { RefetchProvider } from './RefetchContext';
import FederationConfigs from './components/devResources/federationConfigs/FederationConfigs';
import Integrations from './components/devResources/integrations/Integrations';
import Webhooks from './components/devResources/webhooks/Webhooks';
import { DataProvider } from './DataContext';
import ToS from './components/tos/ToS';

const ProtectedRoute = ({ component, ...args }: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

function App() {
  return (
    <div>
      <DataProvider>
        <RefetchProvider>
          <OptionalNavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/developer" element={<ProtectedRoute component={DevResources} />} />
            <Route path="/app-clients" element={<ProtectedRoute component={AppClients} />} />
            <Route path="/fed-config" element={<ProtectedRoute component={FederationConfigs} />} />
            <Route path="/integrations" element={<ProtectedRoute component={Integrations} />} />
            <Route path="/webhooks" element={<ProtectedRoute component={Webhooks} />} />
            <Route path="/tos" element={<ToS />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </RefetchProvider>
      </DataProvider>
    </div>
  );
}

const OptionalNavBar: React.FC = () => {
  const location = useLocation();
  const hideNavbarPaths = ['/tos'];
  if (hideNavbarPaths.includes(location.pathname)) {
    return null;
  } else {
    return <Navbar />;
  }
};

export default App;
