/** read a config value from the environment. missing configs are undefined */
export const getConfigOrUndefined = (key: string): string | undefined => {
  const value = process.env[key];
  return value === undefined ? undefined : `${value}`;
};

/** read a config value from the environment. missing configs will throw */
export const getConfig = (key: string): string => {
  const value = getConfigOrUndefined(key);
  if (value === undefined) {
    throw new Error(`missing environment variable: ${key}`);
  }
  return `${value}`;
};

/** mutate the environment to set a config value to a specific value */
export const setConfig = (key: string, value: unknown): void => {
  const sValue = `${value}`;
  process.env[key] = sValue;
};

/** remove a set environment variable */
export const clearConfig = (key: string): void => {
  delete process.env[key];
};

/**
 * Determine the AWS region
 */
export const getRegion = (): string => {
  // this is where the region is defaulted for our business logic -- AWS_REGION
  // will be set by the AWS runtime in lambda environments.
  // If it's missing its because we're
  // running locally, so make sure to give it garbage to ensure we aren't
  // dependent on it
  return getConfigOrUndefined('AWS_REGION') ?? 'garbage-region-string';
};
