import { StyledTooltip } from './Theme';
import IconButton from '@mui/material/IconButton';
import { InfoOutlined } from '@mui/icons-material';
import React from 'react';

interface InfoToolTipProps {
  id?: string;
  isOffColor?: boolean;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  title: React.ReactNode;
  sx?: React.CSSProperties;
  iconSx?: React.CSSProperties;
}
const InfoToolTip = (props: InfoToolTipProps) => {
  return (
    <StyledTooltip id={props.id} isOffColor={props.isOffColor} placement={props.placement} title={props.title}>
      <IconButton id={`icon-tooltip-${props.id}`} sx={{ ...props.sx }}>
        <InfoOutlined sx={{ ...props.iconSx }} />
      </IconButton>
    </StyledTooltip>
  );
};

export default InfoToolTip;
