import React, { useState } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { StyledTextField } from '../../common/Theme';
import DeleteButton from '../../common/DeleteButton';
import { useMutation } from '@apollo/client';
import { deleteDevGroupGql, updateDevGroupGql } from '../../../helpers/gqlQueries';
import { validateDevGroupName } from '@yonomi/util-validators';
import { trimObjectValues } from '../../../helpers/dataUtils';
import SubmitButtonWithLoader from '../../common/SubmitButtonWithLoader';

const UPDATE_DEV_GROUP = updateDevGroupGql();
const DELETE_DEV_GROUP = deleteDevGroupGql();

interface EditDevGroupFormData {
  name: string;
}

interface EditDevResourceFormProps {
  devGroupId: string;
  devGroupName: string;
  onCompleted: (data: any) => void;
  onDeleteCompleted: (data: any) => void;
  onError: (hasError: boolean, message: string) => void;
}

const EditDevResourceForm = (props: EditDevResourceFormProps) => {
  //GQL
  const [updateDevGroup, { error: updateDevGroupError, loading: updatingDevGroup }] = useMutation(UPDATE_DEV_GROUP);
  const [deleteDevGroup, { error: deleteDevGroupError, loading: deletingDevGroup }] = useMutation(DELETE_DEV_GROUP);

  //States
  const [formData, setFormData] = useState<EditDevGroupFormData>({ name: props.devGroupName });
  const [errors, setErrors] = useState<Record<keyof EditDevGroupFormData, string>>({ name: '' });

  //Handlers
  const validateForm = (data: EditDevGroupFormData) => {
    let valid = true;
    const newErrors: Record<keyof EditDevGroupFormData, string> = { name: '' };

    try {
      validateDevGroupName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Dev Resource Group Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({ name: '' });
    setErrors({ name: '' });
  };

  const handleCompleted = (data: any) => {
    resetForm();
    props.onCompleted(data);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await updateDevGroup({
          variables: { devGroupId: props.devGroupId, name: trimmedData.name },
          onCompleted: (data) => handleCompleted(data),
        });
      } catch (error) {
        let errorMessage = `Failed to update ${props.devGroupName} Dev Resource Group`;
        if (updateDevGroupError) {
          errorMessage = `${errorMessage}: ${updateDevGroupError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      props.onError(false, '');
      await deleteDevGroup({
        variables: { devGroupId: props.devGroupId },
        onCompleted: (data) => props.onDeleteCompleted(data),
      });
    } catch (error) {
      let errorMessage = `Failed to delete ${props.devGroupName} Dev Resource Group`;
      if (deleteDevGroupError) {
        errorMessage = `${errorMessage}: ${deleteDevGroupError.message}`;
      } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
      props.onError(true, errorMessage);
    }
  };

  return (
    <form id={'form-edit-dev-group'} onSubmit={handleEditSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          autoComplete="off"
          id="input-update-dev-name"
          label="Dev Group Name"
          name="name"
          type="text"
          onChange={(event) => {
            setErrors({ ...errors, name: '' });
            handleInputChange(event);
          }}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}
        <SubmitButtonWithLoader
          id={'btn-edit-dev-group-submit'}
          disabled={formData.name.length === 0 || updatingDevGroup || deletingDevGroup}
          label={'Save Changes'}
          loading={updatingDevGroup}
        />
        <DeleteButton
          id={'btn-delete-dev-group'}
          resourceName={props.devGroupName}
          deleteButtonLabel={'Delete Dev Resource Group'}
          deleteButtonOnClick={handleDeleteSubmit}
          deleteButtonDisabled={deletingDevGroup || updatingDevGroup}
          loading={deletingDevGroup}
        />
      </FormControl>
    </form>
  );
};

export default EditDevResourceForm;
