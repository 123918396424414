export const formatDate = (timestamp: string, timeZone: string = 'UTC'): string => {
  try {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone,
    };

    const formattedDate = new Date(timestamp).toLocaleString('en-US', options);
    return `${formattedDate} ${timeZone}`;
  } catch (err) {
    return timestamp;
  }
};

export const stringToArray = (str: string) => {
  return str.split(',').map((item) => item.trim());
};

export const trimObjectValues = <T extends Record<string, any>>(obj: T): T =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
  ) as T;
