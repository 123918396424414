import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { Avatar, MenuItem, useTheme } from '@mui/material';
import {
  AccountCircleOutlined,
  AddBoxOutlined,
  ArticleOutlined,
  Bolt,
  ContactSupportOutlined,
  KeyboardArrowDown,
  LogoutOutlined,
  OpenInNew,
} from '@mui/icons-material';
import logo from '../../assets/allegion64.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { listDevGroupsGql } from '../../helpers/gqlQueries';
import SnackBar, { SnackBarState } from '../common/SnackBar';
import { AccountButton, ContentBox, NavListItemButton, SubMenu } from '../common/Theme';
import ActionModal from '../common/ActionModal';
import { useNavigate } from 'react-router-dom';
import DevResourceGroupMenu from './DevResourceGroupMenu';
import { useRefetch } from '../../RefetchContext';
import BreadCrumbs from '../common/BreadCrumbs';
import CreateDevResourceForm from '../devResources/forms/CreateDevResourceForm';
import Loader from '../common/Loader';
import { useData } from '../../DataContext';

const LIST_DEV_GROUPS = listDevGroupsGql();

const PS_DOCS_LINK = 'https://app.theneo.io/allegion/organizations';
const GETTING_STARTED_LINK = 'https://developers.yonomi.cloud/getting-started';
const SUPPORT_LINK = 'https://developersupport.allegion.com';

const Navbar = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const drawerWidth = theme.width.drawerWidth;
  const onCompleted = useRef((data: any) => {});

  //Auth0
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();

  //GQL
  const { data: devGroupData, loading: loadingDevGroups, refetch } = useQuery(LIST_DEV_GROUPS, {
    skip: !isAuthenticated,
    notifyOnNetworkStatusChange: true,
  });
  const { registerRefetch } = useRefetch();
  registerRefetch('listDevGroups', refetch);

  const { setData } = useData();

  useEffect(() => {
    if (devGroupData) {
      setData('devGroups', devGroupData);
    }
  }, [devGroupData, setData]);

  //States
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createDevGrpOpen, setCreateDevGrpOpen] = useState(false);
  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);
  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [error, setError] = useState({ hasError: false, message: '' });

  //Handlers
  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const userMenuOpen = Boolean(anchorUserMenu);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorUserMenu(null);
  };

  const handleCreateOpen = () => {
    setCreateDevGrpOpen(true);
  };

  const handleCreateClose = () => {
    setCreateDevGrpOpen(false);
  };

  const handleOnError = (hasError: boolean, message: string) => {
    setError({ hasError: hasError, message });
  };

  useEffect(() => {
    if (error.hasError) {
      setSnackBarState({ open: true, message: error.message, severity: 'error' });
    }
  }, [error]);

  onCompleted.current = (data) => {
    handleCreateClose();
    setSnackBarState({
      open: true,
      message: data?.createDevGroup?.name + ' Dev Resource Group successfully created!',
      severity: 'success',
    });
    refetch();
  };

  const handleSnackbarClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/`,
      },
    });
  };

  const hasDevGroups = (devGroupData?.listDevGroups?.edges?.length ?? 0) > 0;

  const drawer = (
    <Box component="div">
      <Toolbar sx={{ mb: 3.5, mt: 3, cursor: 'pointer' }} onClick={() => navigate('/')}>
        <Avatar variant="square" alt="Allegion" src={logo} sx={{ width: 32, height: 32, mr: 1, ml: 1 }} />
        <Typography
          component="div"
          sx={{
            color: 'white',
            fontSize: 18,
            fontFamily: 'Work Sans',
            fontWeight: '300',
            lineHeight: '18px',
          }}
        >
          Yonomi
          <br />
          Platform
        </Typography>
      </Toolbar>
      <Divider />
      {isAuthenticated ? (
        loadingDevGroups ? (
          <Box
            id={'loader-dev-groups'}
            sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}
            data-testid="loader"
          >
            <Loader />
          </Box>
        ) : (
          <List>
            <Typography variant="caption" sx={{ display: 'block', ml: 4, padding: '8px 0px 16px 0px', opacity: 0.6 }}>
              Dev Resource Groups
            </Typography>
            {!hasDevGroups ? (
              <ListItem disablePadding>
                <NavListItemButton id={'btn-add-dev-group'} onClick={handleCreateOpen}>
                  <ListItemIcon>
                    <AddBoxOutlined sx={{ width: '20px', height: '20px' }}></AddBoxOutlined>
                  </ListItemIcon>
                  <ListItemText primary="Add Dev Resource Group" />
                </NavListItemButton>
              </ListItem>
            ) : (
              <>
                {devGroupData?.listDevGroups?.edges?.map((edge: any) => {
                  return (
                    <DevResourceGroupMenu
                      key={edge?.node?.devGroupId}
                      name={edge?.node?.name}
                      devGroupId={edge?.node?.devGroupId}
                      organizationId={edge?.node?.organizationId}
                      hasFedConfigMgmtAccess={edge?.node?.federationManagementConfig?.isActive ?? false}
                    />
                  );
                })}
              </>
            )}
          </List>
        )
      ) : null}
      <Divider />
      <List>
        <ListItem disablePadding>
          <NavListItemButton id={'btn-documentation'} onClick={() => window.open(PS_DOCS_LINK, '_blank')}>
            <ListItemIcon>
              <ArticleOutlined sx={{ width: '20px', height: '20px' }}></ArticleOutlined>
            </ListItemIcon>
            <ListItemText primary="Documentation" primaryTypographyProps={{ variant: 'body1' }} />
            <IconButton edge="end">
              <OpenInNew sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          </NavListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <NavListItemButton id={'btn-quick-start'} onClick={() => window.open(GETTING_STARTED_LINK, '_blank')}>
            <ListItemIcon>
              <Bolt sx={{ width: '20px', height: '20px' }}></Bolt>
            </ListItemIcon>
            <ListItemText primary="Getting Started" primaryTypographyProps={{ variant: 'body1' }} />
            <IconButton edge="end">
              <OpenInNew sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          </NavListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <NavListItemButton id={'btn-support-link'} onClick={() => window.open(SUPPORT_LINK, '_blank')}>
            <ListItemIcon>
              <ContactSupportOutlined sx={{ width: '20px', height: '20px' }}></ContactSupportOutlined>
            </ListItemIcon>
            <ListItemText primary="Support" primaryTypographyProps={{ variant: 'body1' }} />
            <IconButton edge="end">
              <OpenInNew sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          </NavListItemButton>
        </ListItem>
      </List>
      <Box
        component="div"
        sx={{ position: 'fixed', bottom: 0, textAlign: 'center', paddingBottom: 2, width: drawerWidth }}
      >
        {isAuthenticated ? (
          <List>
            <ListItem disablePadding>
              <AccountButton
                id="btn-account-menu"
                aria-controls={userMenuOpen ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={userMenuOpen ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
              >
                <AccountCircleOutlined sx={{ width: 32, height: 32, mr: 1 }} />
                <Typography noWrap variant={'body2'}>
                  {user?.name}
                </Typography>
              </AccountButton>
              <SubMenu
                id="account-menu"
                aria-labelledby="btn-account-menu"
                anchorEl={anchorUserMenu}
                open={userMenuOpen}
                onClose={handleClose}
              >
                <MenuItem id={'btn-logout'} onClick={handleLogout}>
                  <LogoutOutlined />
                  Log Out
                </MenuItem>
              </SubMenu>
            </ListItem>
          </List>
        ) : (
          <AccountButton
            id="btn-nav-login"
            aria-controls={userMenuOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={userMenuOpen ? 'true' : undefined}
            onClick={() => loginWithRedirect({ appState: { returnTo: '/developer' } })}
          >
            <AccountCircleOutlined sx={{ width: 32, height: 32, mr: 1 }} />
            Log In
          </AccountButton>
        )}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth})` },
          ml: { sm: `${drawerWidth}` },
          boxShadow: 'none',
        }}
      >
        <Toolbar sx={{ backgroundColor: theme.palette.background.default }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }}>
            <ContentBox id={'breadcrumbs'}>
              <BreadCrumbs />
            </ContentBox>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={openDrawer}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <ActionModal
        id={'create-dev-group'}
        open={createDevGrpOpen}
        handleClose={handleCreateClose}
        title="Create Dev Resource Group"
        dialogContent={<CreateDevResourceForm onError={handleOnError} onCompleted={onCompleted} />}
      />
      <SnackBar
        id={'notification-snackbar-nav'}
        open={snackBarState.open}
        handleClose={handleSnackbarClose}
        message={snackBarState.message}
        severity={snackBarState.severity}
      />
    </Box>
  );
};

export default Navbar;
