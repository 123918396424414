import Box from '@mui/material/Box';
import {
  IntegrationData,
  MutationResponse,
  SimpleM2MClientData,
  SimpleWebhookConfigData,
} from '../../../../models/DataTypes';
import * as React from 'react';
import { MutableRefObject } from 'react';
import { Button, useTheme } from '@mui/material';
import { useRefetch } from '../../../../RefetchContext';
import SortableTableHead, {
  getComparator,
  HeadCell,
  Order,
  stableSort,
} from '../../../common/tables/SortableTableHead';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Loader from '../../../common/Loader';
import { formatDate } from '../../../../helpers/dataUtils';
import { Edit } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import ActionModal from '../../../common/ActionModal';
import SelectedItemTable from '../../../common/tables/SelectedItemTable';
import { StyledChip, StyledTableContainer } from '../../../common/Theme';
import EditIntegrationForm from '../forms/EditIntegrationForm';
import { getIdFragment } from '../../../../helpers/testUtils';
import { M2mClientsInfoText } from '../../../infoData/M2mClientsInfo';
import { WebhooksInfoText } from '../../../infoData/WebhooksInfo';
import PaginationFooter from '../../../common/PaginationFooter';

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'm2mcreds',
    label: 'Machine to Machine Clients',
    info: M2mClientsInfoText,
  },
  {
    id: 'webhooks',
    label: 'Webhooks',
    info: WebhooksInfoText,
  },
  {
    id: 'createdAt',
    label: 'Date Created',
  },
];

const DEFAULT_ROWS_PER_PAGE = 5;

interface DataTableProps {
  rows: IntegrationData[];
  allM2mClientData: SimpleM2MClientData[];
  allWebhookData: SimpleWebhookConfigData[];
  fetchMore: () => void;
  openCreateM2mClientHandler: () => void;
  openCreateWebhookHandler: () => void;
  onError: (hasError: boolean, message: string) => void;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
  loading: boolean;
}

const IntegrationsTable = ({
  rows,
  allM2mClientData,
  allWebhookData,
  fetchMore,
  openCreateM2mClientHandler,
  openCreateWebhookHandler,
  onCompleted,
  onError,
  loading,
}: DataTableProps) => {
  const theme = useTheme();

  //GQL
  const { refetchByKey } = useRefetch();
  const refetchIntegrations = () => refetchByKey('listIntegrations');

  //States
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IntegrationData>('name');
  const [selected, setSelected] = React.useState<number>();
  const [openViewIntegrationModal, setOpenViewIntegrationModal] = React.useState(false);
  const [openEditIntegrationModal, setOpenEditIntegrationModal] = React.useState(false);
  const [selectedIntegration, setSelectedIntegration] = React.useState<IntegrationData>();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleLastPage = () => {
    fetchMore();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Handlers
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IntegrationData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    if (selected === id) {
      setSelected(-1);
      return;
    }
    setSelected(id);
  };

  const handleViewIntegrationModalOpen = (selectedIntegration: IntegrationData) => {
    setSelectedIntegration(selectedIntegration);
    setOpenViewIntegrationModal(true);
  };

  const handleViewIntegrationModalClose = () => {
    setOpenViewIntegrationModal(false);
  };

  const handleEditIntegrationModalOpen = (selectedIntegration: IntegrationData) => {
    setSelectedIntegration(selectedIntegration);
    setOpenEditIntegrationModal(true);
  };

  const handleEditIntegrationModalClose = () => {
    setOpenEditIntegrationModal(false);
  };

  const handleComplete = (response: MutationResponse) => {
    refetchIntegrations();
    handleEditIntegrationModalClose();
    handleViewIntegrationModalClose();
    onCompleted?.current(response);
  };

  const isSelected = (id: number) => selected === id;

  const sortedRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)), [order, orderBy, rows]);
  const slicedRows =
    rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, backgroundColor: theme.palette.background.default }}>
        <StyledTableContainer>
          <Table id={'table-integrations'} sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody id={`tbody-integrations`}>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1} align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid="loader">
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : rows.length > 0 ? (
                slicedRows.map((row, index) => {
                  const isItemSelected = isSelected(row.index);
                  const labelId = `integration-row-${index}`;

                  return (
                    <TableRow
                      id={`tr-integration-${getIdFragment(row.name)}-${row.index}`}
                      hover
                      onClick={(event) => handleClick(event, row.index)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="normal">
                        <Button
                          id={`btn-view-integration-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleViewIntegrationModalOpen(row)}
                          sx={{ padding: '0px', margin: '0px', textTransform: 'none' }}
                        >
                          {row.name}
                        </Button>
                      </TableCell>
                      <TableCell id={`td-integration-m2m-creds-${getIdFragment(row.name)}-${row.index}`}>
                        {row.machineToMachineClients.length === 0 ? (
                          <Typography variant={'body2'}>None</Typography>
                        ) : (
                          row.machineToMachineClients.map((client, clientIndex) => (
                            <StyledChip key={clientIndex} label={client.name} />
                          ))
                        )}
                      </TableCell>
                      <TableCell id={`td-integration-webhooks-${getIdFragment(row.name)}-${row.index}`}>
                        {row.webhooks.length === 0 ? (
                          <Typography variant={'body2'}>None</Typography>
                        ) : (
                          row.webhooks.map((hook, hookIndex) => <StyledChip key={hookIndex} label={hook.name} />)
                        )}
                      </TableCell>
                      <TableCell id={`td-integration-create-at-${getIdFragment(row.name)}-${row.index}`}>
                        {formatDate(row.createdAt)}
                      </TableCell>

                      <TableCell>
                        <Button
                          id={`btn-edit-integration-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleEditIntegrationModalOpen(row)}
                          sx={{
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0px',
                            padding: '0px',
                          }}
                        >
                          <Edit />
                          <Typography variant={'button'} sx={{ marginLeft: '5px' }}>
                            Edit
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow id={`tr-integration-none`}>
                  <TableCell id={`td-integration-none`} colSpan={headCells.length + 1} align="center">
                    You currently do not have any integrations for this dev group
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <PaginationFooter
            id="pagination-integrations"
            count={sortedRows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            pageChangeHandler={handleChangePage}
            handleLastPage={handleLastPage}
            rowsPerPageChangeHandler={handleChangeRowsPerPage}
          />
        </StyledTableContainer>
      </Paper>
      <ActionModal
        id={'view-integration'}
        open={openViewIntegrationModal}
        handleClose={handleViewIntegrationModalClose}
        subHeader={`Integration`}
        title={selectedIntegration?.name}
        dialogContent={
          selectedIntegration && (
            <SelectedItemTable
              selected={selectedIntegration}
              onEditOpen={handleEditIntegrationModalOpen}
              excludedKeys={['__typename', 'index', 'devGroupId', 'integrationId', 'name']}
            />
          )
        }
      />
      <ActionModal
        id={'edit-integration'}
        open={openEditIntegrationModal}
        handleClose={handleEditIntegrationModalClose}
        title={`Edit Integration`}
        dialogContent={
          selectedIntegration && (
            <EditIntegrationForm
              integrationData={selectedIntegration}
              allM2mClientData={allM2mClientData}
              allWebhookData={allWebhookData}
              openCreateM2mClientHandler={openCreateM2mClientHandler}
              openCreateWebhookHandler={openCreateWebhookHandler}
              onCompleted={handleComplete}
              onError={onError}
            />
          )
        }
      />
    </Box>
  );
};

export default IntegrationsTable;
