import Typography from '@mui/material/Typography';
import React from 'react';

const LogoutUrlsInfo = () => {
  return (
    <Typography variant={'body2'}>
      A set of URLs that are valid to redirect to after logout. After a user logs out, you can redirect them with the
      returnTo query parameter.
      <br />
      <br />
      The URL that you use in returnTo must be listed here.
      <br />
      <br />
      You can specify multiple valid URLs by comma-separating them
    </Typography>
  );
};

export default LogoutUrlsInfo;
