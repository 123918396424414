export enum WebhookEventType {
  DeviceStateUpdated = 'DEVICE_STATE_UPDATED',
  DeviceActionUpdated = 'DEVICE_ACTION_UPDATED',
  DeviceActionCreated = 'DEVICE_ACTION_CREATED',
  DeviceNotificationReported = 'DEVICE_NOTIFICATION_REPORTED',
}

export const getWebhookEventTypeValue = (value: string): WebhookEventType | undefined => {
  return Object.values(WebhookEventType).find((enumValue) => enumValue === value);
};

export const getWebhookEventTypeValues = (values: string[]): WebhookEventType[] => {
  return values.map((value) => getWebhookEventTypeValue(value)).filter((value) => value) as WebhookEventType[];
};
