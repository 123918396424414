import { Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import DevGroupTitleBox from '../DevGroupTitleBox';
import Typography from '@mui/material/Typography';
import InfoToolTip from '../../common/InfoToolTip';
import { Add } from '@mui/icons-material';
import SnackBar, { SnackBarState } from '../../common/SnackBar';
import ActionModal from '../../common/ActionModal';
import { FederationConfigData, MutationResponse } from '../../../models/DataTypes';
import { listFederationConfigsGql } from '../../../helpers/gqlQueries';
import { useRefetch } from '../../../RefetchContext';
import { useQuery } from '@apollo/client';
import FederationConfigTable from './tables/FederationConfigTable';
import CreateFederationConfigForm from './forms/CreateFederationConfigForm';
import { ContainerToolBar, ContentBox, MainContainer, StyledTableTitleRow } from '../../common/Theme';

const LIST_FEDERATION_CONFIGS = listFederationConfigsGql();
const QUERY_MAX_FETCH_COUNT = 30;
const FederationConfigs = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(searchParams);
  const devGroupId = searchParams.get('devGroupId') ?? '';
  const devGroupNameParam = searchParams.get('devGroupName') ?? '';
  const organizationId = searchParams.get('organizationId') || '';

  const onCompleted = useRef((response: MutationResponse) => {});

  //Auth0
  const { isAuthenticated } = useAuth0();

  //GQL
  const { registerRefetch } = useRefetch();
  const {
    data: fedConfigData,
    error: listFedConfigError,
    refetch: refetchFedConfigs,
    loading: loadingFedConfigs,
    fetchMore: fetchMoreFedConfigs,
  } = useQuery(LIST_FEDERATION_CONFIGS, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId, first: 30 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  registerRefetch('listFederationConfigs', refetchFedConfigs);

  //States
  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [error, setError] = useState({ hasError: false, message: '' });
  const [devGroupName, setDevGroupName] = useState(devGroupNameParam);
  const [createFedConfigOpen, setCreateFedConfigOpen] = useState(false);
  const [lastFedConfigCursor, setLastFedConfigCursor] = useState('');

  //Handlers

  const handleSnackbarClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const handleCreateFedConfigOpen = () => {
    setCreateFedConfigOpen(true);
  };

  const handleCreateFedConfigClose = () => {
    setCreateFedConfigOpen(false);
  };

  const delayedNavigate = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate('/developer');
  };

  onCompleted.current = (response: MutationResponse) => {
    const data = response.data;
    setSnackBarState({ open: true, message: response.message, severity: 'success' });
    if (data?.deleteDevGroup) {
      delayedNavigate();
    } else {
      if (data?.updateDevGroup) {
        setDevGroupName(data?.updateDevGroup?.name);
        queryParams.set('devGroupName', data?.updateDevGroup?.name);
        setSearchParams(queryParams.toString());
      } else if (data?.createFederationConfig) {
        setCreateFedConfigOpen(false);
      }
    }
  };

  const handleOnError = (hasError: boolean, message: string) => {
    setError({ hasError, message });
  };

  useEffect(() => {
    if (error.hasError) {
      setSnackBarState({ open: true, message: error.message, severity: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (listFedConfigError) {
      const errorMessage = listFedConfigError?.message || 'Error processing request';
      setError({ hasError: true, message: errorMessage });
    }
  }, [listFedConfigError]);

  useEffect(() => {
    if (fedConfigData) {
      setLastFedConfigCursor(fedConfigData?.listFederationConfigs?.pageInfo?.endCursor ?? '');
    }
  }, [fedConfigData]);

  const handleFetchMoreFedConfigs = async () => {
    const hasNextPage = fedConfigData?.listFederationConfigs?.pageInfo?.hasNextPage;
    if (hasNextPage) {
      fetchMoreFedConfigs({
        variables: { first: QUERY_MAX_FETCH_COUNT, after: lastFedConfigCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prevResult;
          }
          return {
            listFederationConfigs: {
              ...fetchMoreResult.listFederationConfigs,
              edges: [...prevResult.listFederationConfigs.edges, ...fetchMoreResult.listFederationConfigs.edges],
            },
          };
        },
      });
    }
  };

  const fedConfigs: FederationConfigData[] = (fedConfigData?.listFederationConfigs?.edges || []).map(
    (edge: any, index: number) => ({
      ...edge.node,
      devGroup: devGroupName,
      devGroupId: devGroupId,
      index: index,
    })
  );

  return isAuthenticated ? (
    <>
      <MainContainer>
        <ContainerToolBar />
        <Box component="div">
          <DevGroupTitleBox
            devGroupId={devGroupId}
            name={devGroupName}
            organizationId={organizationId}
            onCompleted={onCompleted}
            onError={handleOnError}
          />
          <ContentBox id={'federation-configs-section'}>
            <Box id={'fed-config-table-section'}>
              <StyledTableTitleRow direction="row" spacing={1} justifyContent="space-between" id={'cc-header-row'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" align="left" paragraph sx={{ marginBottom: 0 }}>
                    Federation Configs
                  </Typography>
                  <InfoToolTip
                    id={'tooltip-federation-configs'}
                    placement="right"
                    title={
                      <Typography variant={'body2'}>
                        For users not within the Allegion user pool a Federation Config will need to be set up.
                      </Typography>
                    }
                  />
                </Box>
                {fedConfigs.length === 0 && !loadingFedConfigs && (
                  <Button
                    id={'btn-add-federation-config'}
                    variant="contained"
                    onClick={handleCreateFedConfigOpen}
                    sx={{ paddingLeft: '10px', textTransform: 'none', height: '36px' }}
                  >
                    <Add
                      sx={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                      }}
                    ></Add>
                    <Typography variant="button" sx={{ paddingTop: '2px' }}>
                      Add Federation Config
                    </Typography>
                  </Button>
                )}
              </StyledTableTitleRow>
              <Box>
                <FederationConfigTable
                  rows={fedConfigs}
                  fetchMore={handleFetchMoreFedConfigs}
                  onCompleted={onCompleted}
                  onError={handleOnError}
                  loading={loadingFedConfigs}
                />
              </Box>
            </Box>
          </ContentBox>
          <SnackBar
            id={'notification-snackbar-federation-configs'}
            open={snackBarState.open}
            handleClose={handleSnackbarClose}
            message={snackBarState.message}
            severity={snackBarState.severity}
          />
        </Box>
      </MainContainer>
      <ActionModal
        id={'create-federation-config'}
        open={createFedConfigOpen}
        handleClose={handleCreateFedConfigClose}
        title={'Add Federation Config'}
        dialogContent={
          <CreateFederationConfigForm devGroupId={devGroupId} onCompleted={onCompleted} onError={handleOnError} />
        }
      ></ActionModal>
    </>
  ) : null;
};

export default FederationConfigs;
