import { createTheme } from '@mui/material/styles';
import {
  alpha,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  Menu,
  MenuProps,
  Stack,
  StackProps,
  styled,
  TableContainerProps,
  TextField,
  ToolbarProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Zoom,
} from '@mui/material';
import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

declare module '@mui/material/styles' {
  interface Theme {
    width: {
      drawerWidth: string;
    };
  }
  interface ThemeOptions {
    width?: {
      drawerWidth?: string;
    };
  }
  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

export const SubmitButton = styled((props: ButtonProps) => (
  <Button type="submit" color="primary" variant="contained" {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  width: 'fit-content',
  marginTop: '24px',
}));

export const SubMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'inherit',
    backgroundColor: '#1F1F1F',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(0, 0, 0, 0.24)',
      },
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
  },
  width: '350px',
  '@media (max-width: 600px)': {
    width: '270px',
  },
  marginTop: '24px',
}));

export const NavListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginLeft: '5px',
  paddingLeft: '27px',
  '&:hover, &.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.24)',
  },
  '&.selected': {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.24)',
  },
}));

export const AccountButton = styled((props: ButtonProps) => <Button color="inherit" {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  width: '100%',
  textTransform: 'none',
  padding: '6px 24px',
  borderRadius: '0px',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.24)',
  },
}));

export const StyledChip = styled(({ size, ...props }: ChipProps) => <Chip {...props} />)(({ theme, size }) => ({
  ...(size === 'small' ? theme.typography.caption : theme.typography.body2),
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[900],
  marginRight: '16px',
  '& .MuiChip-deleteIcon': {
    color: '#AFAFAF',
  },
}));

interface EnhancedTooltipProps extends TooltipProps {
  isOffColor?: boolean;
}

export const StyledTooltip = styled(({ className, isOffColor, ...props }: EnhancedTooltipProps) => (
  <Tooltip
    {...props}
    enterTouchDelay={0}
    leaveDelay={1000}
    TransitionComponent={Zoom}
    arrow
    classes={{ popper: className }}
    slotProps={{
      popper: {
        sx: {
          [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
            marginTop: '0px',
          },
          [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
            marginBottom: '0px',
          },
          [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
            marginLeft: '0px',
          },
          [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
            marginRight: '0px',
          },
        },
      },
    }}
    children={props.children}
  />
))(({ theme, isOffColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: isOffColor ? theme.palette.grey[700] : theme.palette.grey[800],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: isOffColor ? theme.palette.grey[700] : theme.palette.grey[800],
  },
}));

export const StyledTableContainer = styled((props: TableContainerProps) => <TableContainer {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey[800],
    borderRadius: '0px 0px 8px 8px',
  })
);

export const StyledTableTitleRow = styled((props: StackProps) => <Stack {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  height: '68px',
  padding: '16px',
  borderRadius: '8px 8px 0px 0px',
}));

export const ContentBox = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: 'auto',
  margin: '0',
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1120px',
    margin: '0 auto',
  },
}));

export const MainContainer = styled((props: BoxProps) => <Box component={'main'} {...props} />)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: `calc(${theme.width.drawerWidth} + ${theme.spacing(4)})`,
  },
}));

export const ContainerToolBar = styled(Toolbar)<ToolbarProps>(({ theme }) => ({
  minHeight: '40px !important',
}));

const Theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#A3A3A3',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'start',
          paddingLeft: '24px',
          paddingBottom: '24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0px 0px',
          fontSize: '36px', //Should mirror typography h4
          fontWeight: 600,
          lineHeight: '40px',
          letterSpacing: '-1px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '48px 24px',
          backgroundColor: '#1F1F1F',
          maxWidth: '696px',
          '@media (max-width: 600px)': {
            maxWidth: '350px',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '5px 0px 1px 0px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: '28px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#FB923C',
            backgroundColor: 'rgba(0, 0, 0, 0.24)',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1F1F1F',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(255,255,255,0.12)',
          fontSize: '14px', //Should mirror typography body2
          fontWeight: 500,
          lineHeight: '16px',
          letterSpacing: '-0.25px',
        },
        head: {
          fontSize: '16px', //Should mirror typography body1
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '-0.5px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingRight: '14px',
          marginRight: '0px',
          width: '335px',
          '@media (max-width: 600px)': {
            width: '270px',
          },
        },
      },
    },
  },
  width: {
    drawerWidth: '256px',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
    h1: {
      fontSize: '102px',
      fontWeight: 400,
      lineHeight: '108px',
      letterSpacing: '-7.25px',
    },
    h2: {
      fontSize: '64px',
      fontWeight: 400,
      lineHeight: '72px',
      letterSpacing: '-4px',
    },
    h3: {
      fontSize: '52px',
      fontWeight: 400,
      lineHeight: '64px',
      letterSpacing: '-2px',
    },
    h4: {
      fontSize: '36px',
      fontWeight: 600,
      lineHeight: '40px',
      letterSpacing: '-1px',
    },
    h5: {
      fontSize: '26px',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '-1px',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '-.5px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-.25px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '-0.25px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-0.5px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '-0.25px',
    },
    button: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '-0.5px',
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '-0.5px',
    },
    overline: {
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: '12px',
      letterSpacing: '-0.25px',
    },
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#0F0F0F',
    },
    primary: {
      main: '#FA8F37',
      light: '#FBB06A',
      dark: '#F96A06',
      darker: '#C74C05',
    },
    secondary: {
      main: '#29343D',
      light: '#87A4AB',
      dark: '#121921',
    },
    success: {
      main: '#9BF3BB',
    },
    error: { main: '#F4A4A4' },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
    },
    grey: {
      200: '#E6E6E6',
      300: '#CCCCCC',
      400: '#999999',
      500: '#808080',
      600: '#4D4D4D',
      700: '#333333',
      800: '#1F1F1F',
      900: '#1A1A1A',
    },
    action: {
      disabledBackground: 'rgba(255,255,255,.12)',
      disabled: 'rgba(255,255,255,.38)',
    },
  },
});

export default Theme;
