import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

const AppTypeInfo = () => {
  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Typography variant={'body2'}>The type of application that will use the credentials</Typography>
        <Typography variant={'body2'}>
          <b>Native:</b> Mobile, desktop, CLI, and smart device apps running natively.
          <br />
          <i>e.g.: iOS, Electron, Apple TV</i>
          apps
        </Typography>
        <Typography variant={'body2'}>
          <b>Regular Web:</b> Traditional web app using redirects.
          <br />
          <i>e.g.: Node.js, Express, ASP.NET, Java, PHP</i>
        </Typography>
        <Typography variant={'body2'}>
          <b>SPA (Single Page Applications):</b> A JavaScript front-end app that uses an API.
          <br />
          <i>e.g.: Angular, React, Vue</i>
        </Typography>
      </Stack>
    </Box>
  );
};

export default AppTypeInfo;
