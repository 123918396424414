import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface DataState {
  [key: string]: any; // Allow for additional properties
}

interface DataContextProps {
  setData: (key: string, value: any) => void;
  getData: (key: string) => any;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setDataState] = useState<DataState>({});

  const setData = useCallback((key: string, value: any) => {
    setDataState((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  const getData = useCallback(
    (key: string) => {
      return data[key];
    },
    [data]
  );

  return <DataContext.Provider value={{ setData, getData }}>{children}</DataContext.Provider>;
};

export const useData = () => {
  const context = useContext(DataContext);

  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }

  return context;
};
