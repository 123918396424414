import Box from '@mui/material/Box';
import { MutationResponse, SimpleIntegrationData, WebhookConfigData } from '../../../../models/DataTypes';
import * as React from 'react';
import { MutableRefObject } from 'react';
import { Button, useTheme } from '@mui/material';
import { useRefetch } from '../../../../RefetchContext';
import SortableTableHead, {
  getComparator,
  HeadCell,
  Order,
  stableSort,
} from '../../../common/tables/SortableTableHead';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Loader from '../../../common/Loader';
import { formatDate } from '../../../../helpers/dataUtils';
import { Edit } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import ActionModal from '../../../common/ActionModal';
import SelectedItemTable from '../../../common/tables/SelectedItemTable';
import EditWebhookConfigForm from '../forms/EditWebhookConfigForm';
import { getIdFragment } from '../../../../helpers/testUtils';
import PaginationFooter from '../../../common/PaginationFooter';
import CopyButton from '../../../common/CopyButton';
import { StyledTableContainer } from '../../../common/Theme';

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'endpoint',
    label: 'Endpoint',
  },
  {
    id: 'createdAt',
    label: 'Date Created',
  },
];

const DEFAULT_ROWS_PER_PAGE = 5;

interface DataTableProps {
  rows: WebhookConfigData[];
  integrations: SimpleIntegrationData[];
  fetchMore: () => void;
  onError: (hasError: boolean, message: string) => void;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
  loading: boolean;
}

const WebhooksTable = ({ rows, fetchMore, onCompleted, onError, loading, integrations }: DataTableProps) => {
  const theme = useTheme();

  //GQL
  const { refetchByKey } = useRefetch();
  const refetchWebhooks = () => refetchByKey('listWebhooks');

  //States
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof WebhookConfigData>('name');
  const [selected, setSelected] = React.useState<number>();
  const [openViewWebhookConfigModal, setOpenViewWebhookConfigModal] = React.useState(false);
  const [openEditWebhookConfigModal, setOpenEditWebhookConfigModal] = React.useState(false);
  const [selectedWebhookConfig, setSelectedWebhookConfig] = React.useState<WebhookConfigData>();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleLastPage = () => {
    fetchMore();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Handlers
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof WebhookConfigData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    if (selected === id) {
      setSelected(-1);
      return;
    }
    setSelected(id);
  };

  const handleViewWebhookConfigModalOpen = (selectedWebhook: WebhookConfigData) => {
    setSelectedWebhookConfig(selectedWebhook);
    setOpenViewWebhookConfigModal(true);
  };

  const handleViewWebhookConfigModalClose = () => {
    setOpenViewWebhookConfigModal(false);
  };

  const handleEditWebhookConfigModalOpen = (selectedWebhook: WebhookConfigData) => {
    setSelectedWebhookConfig(selectedWebhook);
    setOpenEditWebhookConfigModal(true);
  };

  const handleEditWebhookConfigModalClose = () => {
    setOpenEditWebhookConfigModal(false);
  };

  const handleComplete = (response: MutationResponse) => {
    refetchWebhooks();
    handleEditWebhookConfigModalClose();
    handleViewWebhookConfigModalClose();
    onCompleted?.current(response);
  };

  const isSelected = (id: number) => selected === id;

  const sortedRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)), [order, orderBy, rows]);
  const slicedRows =
    rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, backgroundColor: theme.palette.background.default }}>
        <StyledTableContainer>
          <Table id={'table-webhooks'} sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody id={`tbody-webhooks`}>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1} align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid="loader">
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : rows.length > 0 ? (
                slicedRows.map((row, index) => {
                  const isItemSelected = isSelected(row.index);
                  const labelId = `webhook-row-${index}`;

                  return (
                    <TableRow
                      id={`tr-webhook-${getIdFragment(row.name)}-${row.index}`}
                      hover
                      onClick={(event) => handleClick(event, row.index)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="normal">
                        <Button
                          id={`btn-view-webhook-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleViewWebhookConfigModalOpen(row)}
                          sx={{ padding: '0px', margin: '0px', textTransform: 'none' }}
                        >
                          {row.name}
                        </Button>
                      </TableCell>
                      <TableCell id={`td-webhook-description-${getIdFragment(row.name)}-${row.index}`}>
                        {row.description}
                      </TableCell>
                      <TableCell id={`td-webhook-endpoint-${getIdFragment(row.name)}-${row.index}`}>
                        {row.endpoint}
                        <CopyButton
                          id={`btn-copy-webhook-endpoint-${getIdFragment(row.name)}-${index}`}
                          data={row.endpoint}
                        />
                      </TableCell>
                      <TableCell id={`td-webhook-created-at-${getIdFragment(row.name)}-${row.index}`}>
                        {formatDate(row.createdAt)}
                      </TableCell>

                      <TableCell>
                        <Button
                          id={`btn-edit-webhook-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleEditWebhookConfigModalOpen(row)}
                          sx={{
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0px',
                            padding: '0px',
                          }}
                        >
                          <Edit />
                          <Typography variant={'button'} sx={{ marginLeft: '5px' }}>
                            Edit
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow id={`tr-webhook-none`}>
                  <TableCell id={`td-webhook-none`} colSpan={headCells.length + 1} align="center">
                    You currently do not have any webhook configurations for this dev group
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <PaginationFooter
            id="pagination-webhooks"
            count={sortedRows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            pageChangeHandler={handleChangePage}
            handleLastPage={handleLastPage}
            rowsPerPageChangeHandler={handleChangeRowsPerPage}
          />
        </StyledTableContainer>
      </Paper>
      <ActionModal
        id={'view-webhook-config'}
        open={openViewWebhookConfigModal}
        handleClose={handleViewWebhookConfigModalClose}
        subHeader={`Webhook`}
        title={selectedWebhookConfig?.name}
        dialogContent={
          selectedWebhookConfig && (
            <SelectedItemTable
              selected={selectedWebhookConfig}
              onEditOpen={handleEditWebhookConfigModalOpen}
              excludedKeys={['__typename', 'index', 'devGroupId', 'webhookConfigId', 'name']}
              clipboardEnabledKeys={['endpoint']}
            />
          )
        }
      />
      <ActionModal
        id={'edit-webhook-config'}
        open={openEditWebhookConfigModal}
        handleClose={handleEditWebhookConfigModalClose}
        title={`Edit Webhook`}
        dialogContent={
          selectedWebhookConfig && (
            <EditWebhookConfigForm
              webhookData={selectedWebhookConfig}
              integrations={integrations}
              onCompleted={handleComplete}
              onError={onError}
            />
          )
        }
      />
    </Box>
  );
};

export default WebhooksTable;
