import { useNavigate, useSearchParams } from 'react-router-dom';
import { listClientCredsGql, listIntegrationsGql, listM2mCredsGql } from '../../../helpers/gqlQueries';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import SnackBar, { SnackBarState } from '../../common/SnackBar';
import { useEffect, useRef, useState } from 'react';
import { Button, useTheme } from '@mui/material';
import DevGroupTitleBox from '../DevGroupTitleBox';
import Typography from '@mui/material/Typography';
import { Add } from '@mui/icons-material';
import ClientsDataTable from './tables/ClientsDataTable';
import ActionModal from '../../common/ActionModal';
import CreateUserScopeClientForm from './forms/UserScopeClients/CreateUserScopeClientForm';
import InfoToolTip from '../../common/InfoToolTip';
import { useRefetch } from '../../../RefetchContext';
import CreateM2MClientForm from './forms/M2MClients/CreateM2MClientForm';
import { M2MClientData, MutationResponse, SimpleIntegrationData, UserScopeClientData } from '../../../models/DataTypes';
import M2mClientsInfo from '../../infoData/M2mClientsInfo';
import { ContainerToolBar, ContentBox, MainContainer, StyledTableTitleRow } from '../../common/Theme';
import UserScopeClientsInfo from '../../infoData/UserScopeClientsInfo';
import ConfirmationForm from '../../common/ConfirmationForm';

const LIST_USER_SCOPE_CLIENTS = listClientCredsGql();
const LIST_M2M_CLIENTS = listM2mCredsGql();
const LIST_INTEGRATIONS = listIntegrationsGql();

const QUERY_MAX_FETCH_COUNT = 30;

const AppClients = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(searchParams);
  const devGroupId = searchParams.get('devGroupId') ?? '';
  const devGroupNameParam = searchParams.get('devGroupName') ?? '';
  const organizationId = searchParams.get('organizationId') || '';

  const onCompleted = useRef((response: MutationResponse) => {});

  //Auth0
  const { isAuthenticated } = useAuth0();

  //GQL
  const { registerRefetch } = useRefetch();

  const {
    data: userScopeClientsData,
    error: listUserScopeClientsError,
    refetch: refetchUserScopeClients,
    loading: loadingUserScopeClients,
    fetchMore: fetchMoreUserScopeClients,
  } = useQuery(LIST_USER_SCOPE_CLIENTS, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId, first: QUERY_MAX_FETCH_COUNT },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  registerRefetch('listUserScopeClients', refetchUserScopeClients);

  const {
    data: m2mClientsData,
    error: listM2MClientsError,
    refetch: refetchM2MClients,
    loading: loadingM2MClients,
    fetchMore: fetchMoreM2MClients,
  } = useQuery(LIST_M2M_CLIENTS, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId, first: QUERY_MAX_FETCH_COUNT },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  registerRefetch('listM2MClients', refetchM2MClients);

  const { data: integrationsData, error: listIntegrationsError, loading: loadingIntegrations } = useQuery(
    LIST_INTEGRATIONS,
    {
      skip: !isAuthenticated || !devGroupId,
      variables: { devGroupId },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  //States
  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [error, setError] = useState({ hasError: false, message: '' });
  const [devGroupName, setDevGroupName] = useState(devGroupNameParam);
  const [createUserScopeClientOpen, setCreateUserScopeClientOpen] = useState(false);
  const [createM2MClientOpen, setCreateM2MClientOpen] = useState(false);
  const [noIntegrationsWarningOpen, setNoIntegrationsWarningOpen] = useState(false);
  const [lastM2mCursor, setLastM2mCursor] = useState<string>('');
  const [lastClientCursor, setLastClientCursor] = useState<string>('');

  //Handlers

  const handleSnackbarClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const handleCreateUserScopeClientOpen = () => {
    setCreateUserScopeClientOpen(true);
  };

  const handleNoIntegrationsWarningOpen = () => {
    setNoIntegrationsWarningOpen(true);
  };

  const handleCreateUserScopeClientClose = () => {
    setCreateUserScopeClientOpen(false);
  };

  const handleNoIntegrationsWarningClose = () => {
    setNoIntegrationsWarningOpen(false);
  };

  const handleM2MClientOpen = () => {
    if (integrations.length > 0) {
      setCreateM2MClientOpen(true);
    } else {
      handleNoIntegrationsWarningOpen();
    }
  };

  const handleM2MClientClose = () => {
    setCreateM2MClientOpen(false);
  };

  const navigateToIntegration = () => {
    navigate('/integrations?' + searchParams.toString());
  };

  const delayedNavigate = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate('/developer');
  };

  onCompleted.current = (response: MutationResponse) => {
    const data = response.data;
    setSnackBarState({ open: true, message: response.message, severity: 'success' });
    if (data?.deleteDevGroup) {
      delayedNavigate();
    } else {
      if (data?.updateDevGroup) {
        setDevGroupName(data?.updateDevGroup?.name);
        queryParams.set('devGroupName', data?.updateDevGroup?.name);
        setSearchParams(queryParams.toString());
      } else if (data?.createAppClient) {
        setCreateUserScopeClientOpen(false);
        setCreateM2MClientOpen(false);
      }
    }
  };

  const handleOnError = (hasError: boolean, message: string, forceClose = false) => {
    if (forceClose) {
      setCreateUserScopeClientOpen(false);
      setCreateM2MClientOpen(false);
    }
    setError({ hasError, message });
  };

  useEffect(() => {
    if (error.hasError) {
      setSnackBarState({ open: true, message: error.message, severity: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (listUserScopeClientsError || listM2MClientsError || listIntegrationsError) {
      const errorMessage =
        listUserScopeClientsError?.message ||
        listM2MClientsError?.message ||
        listM2MClientsError?.message ||
        'Error processing request';
      setError({ hasError: true, message: errorMessage });
    }
  }, [listUserScopeClientsError, listM2MClientsError, listIntegrationsError]);

  useEffect(() => {
    if (m2mClientsData) {
      setLastM2mCursor(m2mClientsData?.listAppClients?.pageInfo?.endCursor ?? '');
    }
  }, [m2mClientsData]);

  useEffect(() => {
    if (userScopeClientsData) {
      setLastClientCursor(userScopeClientsData?.listAppClients?.pageInfo?.endCursor ?? '');
    }
  }, [userScopeClientsData]);

  const userScopeClients: UserScopeClientData[] = (userScopeClientsData?.listAppClients?.edges || []).map(
    (edge: any, index: number) => ({
      ...edge.node,
      devGroup: devGroupName,
      index: index,
    })
  );

  const m2mClients: M2MClientData[] = (m2mClientsData?.listAppClients?.edges || []).map((edge: any, index: number) => ({
    name: edge.node.name,
    description: edge.node.description,
    clientId: edge.node.clientId,
    clientSecret: edge.node.clientSecret,
    createdAt: edge.node.createdAt,
    updatedAt: edge.node.updatedAt,
    devGroupId: edge.node.devGroupId,
    devGroup: devGroupName,
    integrations: edge.node.m2mCredsIntegrationAssoc.edges.map((edge: any) => {
      return { name: edge.node.integration.name, integrationId: edge.node.integration.integrationId };
    }),
    index: index,
  }));

  const integrations: SimpleIntegrationData[] = (integrationsData?.listIntegrations?.edges || []).map((edge: any) => ({
    integrationId: edge.node.integrationId,
    name: edge.node.name,
  }));

  const handleFetchMoreM2MClients = () => {
    const hasNextPage = m2mClientsData?.listAppClients?.pageInfo?.hasNextPage ?? false;
    if (hasNextPage) {
      fetchMoreM2MClients({
        variables: { first: QUERY_MAX_FETCH_COUNT, after: lastM2mCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prevResult;
          }
          return {
            listAppClients: {
              ...fetchMoreResult.listAppClients,
              edges: [...prevResult.listAppClients.edges, ...fetchMoreResult.listAppClients.edges],
            },
          };
        },
      });
    }
  };

  const handleFetchMoreUserScopeClients = () => {
    const hasNextPage = userScopeClientsData?.listAppClients?.pageInfo?.hasNextPage ?? false;
    if (hasNextPage) {
      fetchMoreUserScopeClients({
        variables: { first: QUERY_MAX_FETCH_COUNT, after: lastClientCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prevResult;
          }
          return {
            listAppClients: {
              ...fetchMoreResult.listAppClients,
              edges: [...prevResult.listAppClients.edges, ...fetchMoreResult.listAppClients.edges],
            },
          };
        },
      });
    }
  };

  return isAuthenticated ? (
    <>
      <MainContainer>
        <ContainerToolBar />
        <Box component="div">
          <DevGroupTitleBox
            devGroupId={devGroupId}
            name={devGroupName}
            organizationId={organizationId}
            onCompleted={onCompleted}
            onError={handleOnError}
          />
          <ContentBox id={'app-clients-section'}>
            <Typography variant="h4" align="left" paragraph noWrap>
              Application Clients
            </Typography>
            <Box id={'m2m-credentials-section'}>
              <StyledTableTitleRow direction="row" spacing={1} justifyContent="space-between" id={'cc-header-row'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" align="left" paragraph sx={{ marginBottom: 0 }}>
                    Machine to Machine Clients
                  </Typography>
                  <InfoToolTip id={'tooltip-m2m-creds'} placement="right" title={<M2mClientsInfo />} />
                </Box>
                {!loadingM2MClients && !loadingIntegrations && (
                  <Button
                    id={'btn-add-m2m-creds'}
                    variant="contained"
                    onClick={handleM2MClientOpen}
                    sx={{ paddingLeft: '10px', textTransform: 'none', height: '36px' }}
                  >
                    <Add
                      sx={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                      }}
                    ></Add>
                    <Typography variant="button" sx={{ paddingTop: '2px' }}>
                      Add M2M Client
                    </Typography>
                  </Button>
                )}
              </StyledTableTitleRow>
              <Box>
                <ClientsDataTable
                  rows={m2mClients}
                  fetchMore={handleFetchMoreM2MClients}
                  integrations={integrations}
                  onCompleted={onCompleted}
                  onError={handleOnError}
                  loading={loadingM2MClients}
                  isM2m={true}
                />
              </Box>
            </Box>
            <Box id={'client-credentials-section'} sx={{ marginTop: '24px' }}>
              <StyledTableTitleRow direction="row" spacing={1} justifyContent="space-between" id={'cc-header-row'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" align="left" paragraph sx={{ marginBottom: 0 }}>
                    User Scope Clients
                  </Typography>
                  <InfoToolTip id={'tooltip-client-creds'} placement="right" title={<UserScopeClientsInfo />} />
                </Box>
                {!loadingUserScopeClients && (
                  <Button
                    id={'btn-add-client-creds'}
                    variant="contained"
                    onClick={handleCreateUserScopeClientOpen}
                    sx={{ paddingLeft: '10px', textTransform: 'none', height: '36px' }}
                  >
                    <Add
                      sx={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                      }}
                    ></Add>
                    <Typography variant="button" sx={{ paddingTop: '2px' }}>
                      Add User Scope Client
                    </Typography>
                  </Button>
                )}
              </StyledTableTitleRow>
              <Box>
                <ClientsDataTable
                  rows={userScopeClients}
                  fetchMore={handleFetchMoreUserScopeClients}
                  integrations={[]}
                  onCompleted={onCompleted}
                  onError={handleOnError}
                  loading={loadingUserScopeClients}
                />
              </Box>
            </Box>
          </ContentBox>
          <SnackBar
            id={'notification-snackbar-app-clients'}
            open={snackBarState.open}
            handleClose={handleSnackbarClose}
            message={snackBarState.message}
            severity={snackBarState.severity}
          />
        </Box>
      </MainContainer>
      <ActionModal
        id={'create-m2m-creds'}
        open={createM2MClientOpen}
        handleClose={handleM2MClientClose}
        title={'Add Machine to Machine Client'}
        dialogContent={
          <CreateM2MClientForm
            devGroupId={devGroupId}
            integrations={integrations}
            onCompleted={onCompleted}
            onError={handleOnError}
          />
        }
      ></ActionModal>
      <ActionModal
        id={'create-client-creds'}
        open={createUserScopeClientOpen}
        handleClose={handleCreateUserScopeClientClose}
        title={'Add User Scope Client'}
        dialogContent={
          <CreateUserScopeClientForm devGroupId={devGroupId} onCompleted={onCompleted} onError={handleOnError} />
        }
      ></ActionModal>
      <ActionModal
        id={'no-integrations-warning'}
        open={noIntegrationsWarningOpen}
        handleClose={handleNoIntegrationsWarningClose}
        title={'Integration Required to Proceed'}
        titleSx={{
          ...theme.typography.h5,
          padding: '24px 24px',
        }}
        paperSx={{ padding: '0px 0px', borderRadius: '8px' }}
        showTitleDivider
        showCloseButton={false}
        dialogContent={
          <ConfirmationForm
            id={'modal-no-integrations-warning'}
            handleClose={handleNoIntegrationsWarningClose}
            handleConfirm={navigateToIntegration}
            message={`An integration is required to create a Machine to Machine Client.\n\nClick "OK" below to go to the Integrations page and choose "Add Integration". Once you've added an integration you can create a Machine to Machine Client.`}
            confirmButtonLabel={'Go to Integrations'}
          />
        }
      />
    </>
  ) : null;
};

export default AppClients;
