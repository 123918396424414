import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme } from '@mui/material';
import InfoToolTip from '../../common/InfoToolTip';
import Loader from '../../common/Loader';

interface DevResourceCredentialBoxProps {
  name: string;
  itemCount: string;
  loading?: boolean;
  id?: string;
  sx?: React.CSSProperties;
  tooltip?: React.ReactNode;
}
const DevResourceClientsBox = ({ name, itemCount, loading, tooltip }: DevResourceCredentialBoxProps) => {
  const theme = useTheme();
  const idName = name.toLowerCase().replace(/\s+/g, '-');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.grey[700],
        borderRadius: '4px',
        paddingY: '8px',
        paddingRight: '8px',
        margin: '8px',
      }}
    >
      <Box
        id={`label-dev-group-metrics-credential-${idName}`}
        data-testid={`label-dev-group-metrics-credential-${idName}`}
        sx={{
          display: 'flex',
          flex: 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <InfoToolTip
          id={`tooltip-dev-group-metrics-credential-${idName}`}
          placement="bottom"
          title={tooltip}
          isOffColor={true}
        />
        <Typography variant="caption">{name}</Typography>
      </Box>
      {loading ? (
        <Box
          id={`loader-dev-group-metrics-credential-${idName}-item-count`}
          data-testid={`loader-dev-group-metrics-credential-${idName}-item-count`}
        >
          <Loader />
        </Box>
      ) : (
        <Typography
          id={`label-dev-group-metrics-credential-${idName}-item-count`}
          data-testid={`label-dev-group-metrics-credential-${idName}-item-count`}
          variant="h5"
          align="right"
          sx={{ flex: 1 }}
        >
          {itemCount}
        </Typography>
      )}
    </Box>
  );
};

export default DevResourceClientsBox;
