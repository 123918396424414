import { CircularProgress, circularProgressClasses, CircularProgressProps } from '@mui/material';
import Box from '@mui/material/Box';

function Loader(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.primary.darker,
        }}
        size={30}
        thickness={7}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => theme.palette.primary.light,
          animationDuration: '800ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={30}
        thickness={7}
        {...props}
      />
    </Box>
  );
}

export default Loader;
