import { Breadcrumbs, Link, LinkProps, useTheme } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

const breadcrumbNameMap: { [key: string]: string } = {
  '/developer': 'Dev Resource Group',
  '/integrations': 'Integrations',
  '/app-clients': 'Application Clients',
  '/fed-config': 'Federation Config',
  '/webhooks': 'Webhooks',
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link variant={'body2'} {...props} component={RouterLink as any} />;
}

const isDevResource = (path: string) => {
  return path === '/integrations' || path === '/app-clients' || path === '/fed-config' || path === '/webhooks';
};

const BreadCrumbs = () => {
  const theme = useTheme();
  const location = useLocation();

  const [devGroupName, setDevGroupName] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const devGroupNameParam = searchParams.get('devGroupName');
    setDevGroupName(devGroupNameParam ?? '');
  }, [location.search]);

  const pathnames = location.pathname.split('/').filter((x) => x);
  const devGrpNameSuffix = devGroupName && location.pathname === '/developer' ? `: ${devGroupName}` : null;

  return (
    <Breadcrumbs
      separator={<NavigateNext sx={{ color: theme.palette.grey[400] }} fontSize="small" />}
      aria-label="breadcrumb"
    >
      {/*TODO: This is only necessary while we use base paths for our dev resources. Once we start using nested paths then this can be removed.*/}
      {isDevResource(location.pathname) ? (
        <LinkRouter id="link-breadcrumb-developer" underline="hover" color="primary.main" to="/developer">
          {breadcrumbNameMap['/developer']}
          {devGroupName ? `: ${devGroupName}` : null}
        </LinkRouter>
      ) : null}

      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="primary.main" variant={'body2'} key={to}>
            {breadcrumbNameMap[to]}
            {devGrpNameSuffix}
          </Typography>
        ) : (
          <LinkRouter
            id={`link-breadcrumb-${to.replaceAll('/', '')}`}
            underline="hover"
            color="primary.main"
            to={to}
            key={to}
          >
            {breadcrumbNameMap[to]}
            {devGrpNameSuffix}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
