import React, { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';

interface RefetchContextProps {
  registerRefetch: (key: string, refetchFn: () => void) => void;
  unregisterRefetch: (key: string) => void;
  refetchByKey: (key: string) => void;
}

const RefetchContext = createContext<RefetchContextProps | undefined>(undefined);

interface RefetchProviderProps {
  children: ReactNode;
}

export const RefetchProvider: React.FC<RefetchProviderProps> = ({ children }) => {
  const refetchMap = useMemo(() => new Map<string, () => void>(), []);

  const registerRefetch = useCallback(
    (key: string, refetchFn: () => void) => {
      refetchMap.set(key, refetchFn);
    },
    [refetchMap]
  );

  const refetchByKey = useCallback(
    (key: string) => {
      const refetchFn = refetchMap.get(key);
      if (refetchFn) {
        refetchFn();
      }
    },
    [refetchMap]
  );

  const unregisterRefetch = useCallback(
    (key: string) => {
      refetchMap.delete(key);
    },
    [refetchMap]
  );

  return (
    <RefetchContext.Provider value={{ registerRefetch, unregisterRefetch, refetchByKey }}>
      {children}
    </RefetchContext.Provider>
  );
};

export const useRefetch = () => {
  const context = useContext(RefetchContext);

  if (!context) {
    throw new Error('useRefetch must be used within a RefetchProvider');
  }

  return context;
};
