import { Dialog, DialogContent, DialogTitle, Divider, Paper, PaperProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import React from 'react';
import Draggable from 'react-draggable';
import Typography from '@mui/material/Typography';

const PaperComponent = (props: PaperProps) => {
  const nodeRef = React.useRef(null);
  return (
    <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
};

interface ActionModalProps {
  id: string;
  open: boolean;
  showCloseButton?: boolean;
  handleClose: () => void;
  subHeader?: string;
  title?: string;
  dialogContent: React.ReactNode;
  sx?: React.CSSProperties;
  paperSx?: React.CSSProperties;
  titleSx?: React.CSSProperties;
  showTitleDivider?: boolean;
}

const ActionModal = ({
  id,
  open,
  handleClose,
  subHeader,
  title,
  dialogContent,
  sx,
  showCloseButton = true,
  paperSx,
  titleSx,
  showTitleDivider = false,
}: ActionModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disableRestoreFocus
      sx={{ ...sx, '& .MuiDialog-paper': { ...paperSx } }}
    >
      {subHeader && (
        <Typography variant="h6" sx={{ fontWeight: 400, opacity: 0.6 }}>
          {subHeader}
        </Typography>
      )}
      <DialogTitle sx={{ cursor: 'move', maxWidth: '630px', ...titleSx }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      {showTitleDivider && <Divider />}
      <DialogContent>{dialogContent}</DialogContent>
      {showCloseButton && (
        <IconButton
          id={`btn-close-modal-${id}`}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <Close />
        </IconButton>
      )}
    </Dialog>
  );
};

export default ActionModal;
