import Typography from '@mui/material/Typography';
import React from 'react';

export const M2mClientsInfoText =
  "M2M clients provide authorization bearer tokens for making system-to-system requests to the Yonomi IoT Platform.\n\nThese credentials have limited permissions for organization and lifecycle management.\n\nOnce configured, you'll receive a client ID and secret.";

const M2mClientsInfo = () => {
  return (
    <Typography variant={'body2'}>
      M2M clients provide authorization bearer tokens for
      <br />
      making system-to-system requests to the Yonomi IoT Platform.
      <br />
      <br />
      These clients have limited permissions for organization and lifecycle management.
      <br />
      <br />
      Once configured, you'll receive a client ID and secret.
    </Typography>
  );
};

export default M2mClientsInfo;
