import React, { useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import SubmitButtonWithLoader from '../../common/SubmitButtonWithLoader';
import { getConfig } from '@yonomi/util-config';

interface AcceptToSFormData {
  accepted: boolean;
}

interface AcceptToSFormProps {
  state: string;
  onCompleted: (data: any) => void;
  onError: (hasError: boolean, message: string) => void;
}

const AcceptToSForm = (props: AcceptToSFormProps) => {
  const domain = getConfig('REACT_APP_DOMAIN_NAME');
  //States
  const [formData, setFormData] = useState<AcceptToSFormData>({ accepted: false });

  //Handlers
  const handleAcceptSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    window.location.href = `https://${domain}/continue?state=${props.state}`;
  };

  return (
    <form id={'form-accept-tos'} onSubmit={handleAcceptSubmit}>
      <FormControl sx={{ width: '100%' }}>
        <TextField
          multiline
          rows={10}
          fullWidth
          variant="outlined"
          value={`Terms of Service....`}
          InputProps={{
            readOnly: true,
          }}
          sx={{ marginBottom: 2, marginTop: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox checked={formData.accepted} onChange={(e) => setFormData({ accepted: e.target.checked })} />
          }
          label="I accept the terms of service"
        />
        <SubmitButtonWithLoader
          id={'btn-accept-tos-submit'}
          disabled={!formData.accepted}
          label={'Accept'}
          loading={false}
        />
      </FormControl>
    </form>
  );
};

export default AcceptToSForm;
