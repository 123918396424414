import ActionModal from '../common/ActionModal';
import React from 'react';
import AcceptToSForm from './forms/AcceptToSForm';
import { useSearchParams } from 'react-router-dom';

const ToS = () => {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state') ?? '';
  return (
    <ActionModal
      id={'edit-dev-group'}
      open={true}
      showCloseButton={false}
      handleClose={() => {}}
      title="Terms of Service"
      dialogContent={<AcceptToSForm state={state} onCompleted={() => {}} onError={() => {}} />}
    />
  );
};

export default ToS;
