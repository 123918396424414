import Typography from '@mui/material/Typography';
import React from 'react';

const CallbackUrlsInfo = () => {
  return (
    <Typography variant={'body2'}>
      After the user authenticates we will only call back to any of these URLs.
      <br />
      <br />
      Specify multiple valid URLs, separated by commas, to handle different environments (e.g., QA or testing).
      <br />
      <br />
      Use HTTPS protocol to avoid callback failures. Except for custom URI schemes, all callbacks should use HTTPS.
      <br />
      <br />
      These credentials have limited permissions for organization and lifecycle management. Upon configuration, you'll
      receive a client ID and secret.
    </Typography>
  );
};

export default CallbackUrlsInfo;
