import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, Stack, useTheme } from '@mui/material';
import { Spoke } from '@mui/icons-material';
import BoxWithTitle from '../common/BoxWithTitle';
import LoginButton from '../common/LoginButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useSearchParams } from 'react-router-dom';
import Loader from '../common/Loader';
import { MainContainer } from '../common/Theme';

const Home = () => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth0();

  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code');

  if (isAuthenticated) {
    return <Navigate to={'/developer'} />;
  }

  return (
    <MainContainer>
      <Toolbar />
      <Box component="div">
        <Toolbar />
        <Box component="div" display="flex" justifyContent="center">
          <Box component="div" sx={{ width: '100%', maxWidth: '1000px', px: 2 }}>
            {authCode ? (
              <Loader />
            ) : (
              <Stack direction="column" alignItems="center" gap={1}>
                <Avatar sx={{ width: '125px', height: '125px', background: theme.palette.grey[300], mb: 5 }}>
                  <Spoke sx={{ width: '72px', height: '72px', color: theme.palette.grey[100] }}></Spoke>
                </Avatar>
                <BoxWithTitle
                  title={'Welcome to the Yonomi Platform'}
                  children={
                    <Typography id={'text-welcome-text'} variant={'body1'} align="center" paragraph>
                      The Yonomi Platform Developer Portal is a self-service console for partner developers to manage
                      platform integration. It allows developers to configure settings and access documentation, guides,
                      and API/SDK assets for project development.
                    </Typography>
                  }
                />
                <LoginButton id={'btn-login'} sx={{ position: 'relative', top: '-26px' }} />
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default Home;
