import { formatDate } from '../../../helpers/dataUtils';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Stack, useTheme } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { ClientData, FederationConfigData, IntegrationData, WebhookConfigData } from '../../../models/DataTypes';
import { StyledChip } from '../Theme';
import MaskedTextField from '../MaskedTextField';
import CopyButton from '../CopyButton';

interface SelectedItemTableProps {
  selected: ClientData | FederationConfigData | IntegrationData | WebhookConfigData;
  onEditOpen: (data: any) => void;
  excludedKeys: string[];
  maskedKeys?: string[];
  clipboardEnabledKeys?: string[];
}

const isEmpty = (value: any) => {
  return value === undefined || value === null || value.toString() === '';
};

const SelectedItemTable = ({
  selected,
  onEditOpen,
  excludedKeys,
  maskedKeys,
  clipboardEnabledKeys,
}: SelectedItemTableProps) => {
  const theme = useTheme();

  return (
    <Box>
      <TableContainer
        sx={{
          marginTop: '16px',
          border: `solid 1px rgba(255,255,255,0.12)`,
          borderRadius: '4px',
        }}
      >
        <Table id={`table-view-item`}>
          <TableBody>
            {Object.entries(selected).map(([key, value]) => {
              const formattedKey = key.toString().toLowerCase().replace(/\s+/g, '-');
              return excludedKeys.includes(key) ? null : (
                <TableRow key={key}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ textTransform: 'capitalize', minWidth: '220px', width: '220px' }}
                  >
                    <Typography id={`selected-item-label-${key}`} variant="body1">
                      {key.replace(/[A-Z]/g, (match) => ` ${match}`)}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ wordBreak: 'break-all', padding: '0px 16px' }}>
                    {Array.isArray(value) ? (
                      value.map((val, index) =>
                        key === 'machineToMachineClients' || key === 'webhooks' || key === 'integrations' ? (
                          <StyledChip
                            id={`selected-item-value-${key}`}
                            size={'small'}
                            key={index}
                            label={val?.name?.toString()}
                          />
                        ) : (
                          <Typography id={`selected-item-value-${key}`} key={index} variant="body1">
                            {val?.toString()}
                            {clipboardEnabledKeys?.includes(key) && !isEmpty(value) && (
                              <CopyButton id={`btn-copy-${formattedKey}-${index}`} data={val?.toString()} />
                            )}
                          </Typography>
                        )
                      )
                    ) : (
                      <Typography id={`selected-item-value-${key}`} variant="body1">
                        {key === 'createdAt' || key === 'updatedAt' ? (
                          formatDate(value)
                        ) : maskedKeys?.includes(key) ? (
                          <Stack direction="row" spacing={1}>
                            <MaskedTextField
                              id={`masked-selected-item-${key}`}
                              value={value}
                              sx={{
                                flexGrow: 1,
                                justifyContent: 'left',
                              }}
                              primaryTypographyProps={theme.typography.body1}
                            />
                            <CopyButton id={`btn-copy-${formattedKey}`} data={value} />
                          </Stack>
                        ) : (
                          <>
                            {value?.toString()}
                            {clipboardEnabledKeys?.includes(key) && !isEmpty(value) && (
                              <CopyButton id={`btn-copy-${formattedKey}`} data={value?.toString()} />
                            )}
                          </>
                        )}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        id={'btn-edit-item'}
        variant="contained"
        onClick={() => onEditOpen(selected)}
        sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', marginTop: '24px' }}
      >
        <Edit />
        <Typography variant={'button'} sx={{ marginLeft: '5px' }}>
          Edit
        </Typography>
      </Button>
    </Box>
  );
};

export default SelectedItemTable;
