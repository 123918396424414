import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

const BoxWithTitle = ({ title, children }: { title: string; children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Box
      component="fieldset"
      sx={{
        border: `1px ${theme.palette.grey[500]} solid`,
        borderRadius: '16px',
        padding: '25px 60px',
      }}
    >
      <legend
        style={{
          margin: '0 auto',
          padding: '0 10px',
          position: 'relative',
          textAlign: 'center',
          width: 'auto',
          ...theme.typography.h5,
        }}
      >
        {title}
      </legend>
      {children}
    </Box>
  );
};

export default BoxWithTitle;
