import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Error, WarningAmberOutlined } from '@mui/icons-material';
import Loader from './Loader';
import { debounce } from '../../helpers/debounce';

interface TypeToDeleteConfirmationFormProps {
  id?: string;
  message?: string;
  deletionWarningMessage: string;
  resourceName?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  confirmButtonStyle?: React.CSSProperties;
  cancelButtonStyle?: React.CSSProperties;
  handleClose: () => void;
  handleConfirm: () => void;
  mainContent?: React.ReactNode;
  actionsSx?: React.CSSProperties;
  contentSx?: React.CSSProperties;
  loading?: boolean;
}

export const TypeToDeleteConfirmationForm = ({
  id,
  message,
  deletionWarningMessage,
  resourceName,
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  confirmButtonStyle,
  cancelButtonStyle,
  handleClose,
  handleConfirm,
  actionsSx,
  contentSx,
  loading = false,
}: TypeToDeleteConfirmationFormProps) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const [showError, setShowError] = useState(false);

  const debounceCheckInput = useRef(
    debounce((value: string) => {
      if (value !== 'DELETE' && value !== '') {
        setShowError(true);
      }
    }, 600)
  ).current;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setShowError(false);
    debounceCheckInput(event.target.value);
  };

  useEffect(() => {
    return () => {
      debounceCheckInput.cancel();
    };
  }, [debounceCheckInput]);

  const isConfirmDisabled = inputValue !== 'DELETE';

  return (
    <>
      <DialogContent id={id} sx={{ padding: '32px 24px', ...contentSx }}>
        {loading ? (
          <Box
            id={'loader-delete-btn'}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            data-testid="loader-delete-btn"
          >
            <Loader />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: theme.palette.error.main,
                marginBottom: '12px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '120px',
                  alignItems: 'center',
                  color: theme.palette.error.main,
                  padding: '16px 16px',
                  backgroundColor: '#321801',
                  border: '1px solid #FDEE8C',
                  borderRadius: '4px',
                  borderLeftWidth: '8px',
                  marginBottom: '32px',
                }}
              >
                <WarningAmberOutlined
                  sx={{ marginRight: '16px', verticalAlign: 'middle', color: '#FDEE8C', fontSize: '24px' }}
                />
                <Box>
                  <Typography variant="h6" sx={{ color: '#FFFFFF', marginBottom: '4px', fontWeight: 700 }}>
                    Warning
                  </Typography>
                  <Typography data-testid="text-warning-deletion-message" variant="body2" sx={{ color: '#FFFFFF' }}>
                    {deletionWarningMessage}
                  </Typography>
                </Box>
              </Box>

              <DialogContentText sx={{ color: '#FFFFFF' }}>{message}</DialogContentText>
            </Box>
            <TextField
              id="input-delete-confirmation"
              data-testid="input-delete-confirmation"
              fullWidth
              variant="outlined"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type DELETE"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: showError ? theme.palette.error.main : undefined,
                  },
                  '&:hover fieldset': {
                    borderColor: showError ? theme.palette.error.main : undefined,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: showError ? theme.palette.error.main : undefined,
                  },
                },
              }}
            />
            <Box sx={{ height: '32px', marginTop: '8px' }}>
              {showError && (
                <DialogContentText
                  id="text-error-message"
                  data-testid="text-error-message"
                  sx={{ marginTop: '8px', color: theme.palette.error.main }}
                >
                  <Error sx={{ marginRight: '4px', verticalAlign: 'middle' }} />
                  Please type <strong>"DELETE"</strong> to delete the {resourceName}.
                </DialogContentText>
              )}
            </Box>
          </>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'flex-end', padding: '16px 24px', ...actionsSx }}>
        <Button
          id={`btn-${id}-cancel`}
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            padding: '12px 16px',
            borderColor: theme.palette.grey[700],
            color: theme.palette.primary.main,
            ...cancelButtonStyle,
          }}
          color="primary"
          disabled={loading}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          id={`btn-${id}-confirm`}
          onClick={handleConfirm}
          variant="contained"
          sx={{ textTransform: 'none', padding: '12px 16px', ...confirmButtonStyle }}
          autoFocus
          disabled={loading || isConfirmDisabled}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </>
  );
};

export default TypeToDeleteConfirmationForm;
