import { Button, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ActionModal from './ActionModal';
import ConfirmationForm from './ConfirmationForm';
import TypeToDeleteConfirmationForm from './TypeToDeleteConfirmationForm';

interface DeleteButtonProps {
  id?: string;
  resourceName: string;
  deleteButtonLabel: string;
  deleteButtonOnClick: () => void;
  deleteButtonDisabled: boolean;
  loading: boolean;
  deletionWarningMessage?: string;
  sx?: React.CSSProperties;
}

const DeleteButton = ({
  id,
  resourceName,
  deleteButtonLabel,
  deleteButtonOnClick,
  deleteButtonDisabled,
  loading,
  deletionWarningMessage,
  sx,
}: DeleteButtonProps) => {
  const theme = useTheme();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDeleteDialogOpen = () => {
    setShowDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setShowDeleteDialog(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          id={id}
          variant="outlined"
          onClick={handleDeleteDialogOpen}
          sx={{
            textTransform: 'none',
            width: 'fit-content',
            marginTop: '24px',
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
            '&:hover': {
              borderColor: theme.palette.error.main,
              backgroundColor: theme.palette.background.default,
            },
            ...sx,
          }}
          disabled={deleteButtonDisabled}
        >
          {deleteButtonLabel}
        </Button>
      </Box>
      <ActionModal
        id={'action-modal-confirm-delete'}
        open={showDeleteDialog}
        handleClose={handleDeleteDialogClose}
        title={`${deleteButtonLabel}?`}
        titleSx={{
          ...theme.typography.h5,
          padding: '24px 24px',
        }}
        paperSx={{ padding: '0px 0px', borderRadius: '8px' }}
        showTitleDivider
        showCloseButton={false}
        dialogContent={
          deletionWarningMessage ? (
            <TypeToDeleteConfirmationForm
              id={'modal-confirm-delete'}
              message={`To confirm deletion, enter the word 'DELETE' into the text input to successfully delete the ${resourceName}.`}
              deletionWarningMessage={deletionWarningMessage}
              resourceName={resourceName}
              confirmButtonLabel={deleteButtonLabel}
              confirmButtonStyle={{ backgroundColor: theme.palette.error.main }}
              handleClose={handleDeleteDialogClose}
              handleConfirm={deleteButtonOnClick}
              loading={loading}
            />
          ) : (
            <ConfirmationForm
              id={'modal-confirm-delete'}
              handleClose={handleDeleteDialogClose}
              handleConfirm={deleteButtonOnClick}
              message={`This action cannot be undone.`}
              confirmButtonLabel={deleteButtonLabel}
              confirmButtonStyle={{ backgroundColor: theme.palette.error.main }}
              loading={loading}
            />
          )
        }
      ></ActionModal>
    </>
  );
};

export default DeleteButton;
