import * as React from 'react';
import { MutableRefObject } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack, useTheme } from '@mui/material';
import { formatDate } from '../../../../helpers/dataUtils';
import ActionModal from '../../../common/ActionModal';
import Typography from '@mui/material/Typography';
import SelectedItemTable from '../../../common/tables/SelectedItemTable';
import { Edit } from '@mui/icons-material';
import EditUserScopeClientForm from '../forms/UserScopeClients/EditUserScopeClientForm';
import { useRefetch } from '../../../../RefetchContext';
import EditM2MClientForm from '../forms/M2MClients/EditM2MClientForm';
import Loader from '../../../common/Loader';
import {
  ClientData,
  M2MClientData,
  MutationResponse,
  SimpleIntegrationData,
  UserScopeClientData,
} from '../../../../models/DataTypes';
import SortableTableHead, {
  getComparator,
  HeadCell,
  Order,
  stableSort,
} from '../../../common/tables/SortableTableHead';
import { getIdFragment } from '../../../../helpers/testUtils';
import MaskedTextField from '../../../common/MaskedTextField';
import CopyButton from '../../../common/CopyButton';
import PaginationFooter from '../../../common/PaginationFooter';
import { StyledTableContainer } from '../../../common/Theme';

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'clientId',
    label: 'Client ID',
  },
  {
    id: 'clientSecret',
    label: 'Client Secret',
  },
  {
    id: 'createdAt',
    label: 'Date Created',
  },
];

const DEFAULT_ROWS_PER_PAGE = 2;

interface DataTableProps {
  rows: ClientData[];
  integrations: SimpleIntegrationData[];
  fetchMore: () => void;
  onError: (hasError: boolean, message: string) => void;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
  loading: boolean;
  isM2m?: boolean;
}

const ClientsDataTable = ({ isM2m, rows, fetchMore, integrations, onCompleted, onError, loading }: DataTableProps) => {
  const theme = useTheme();

  //GQL
  const { refetchByKey } = useRefetch();
  const refetchClientCreds = () => refetchByKey('listUserScopeClients');
  const refetchM2MCreds = () => refetchByKey('listM2MClients');

  //States
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ClientData>('name');
  const [selected, setSelected] = React.useState<number>();
  const [openViewCredentialModal, setOpenViewCredentialModal] = React.useState(false);
  const [openEditCredentialModal, setOpenEditCredentialModal] = React.useState(false);
  const [selectedCred, setSelectedCred] = React.useState<ClientData>();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleLastPage = () => {
    fetchMore();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Handlers
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ClientData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    if (selected === id) {
      setSelected(-1);
      return;
    }
    setSelected(id);
  };

  const handleViewCredentialModalOpen = (selectedCred: ClientData) => {
    setSelectedCred(selectedCred);
    setOpenViewCredentialModal(true);
  };

  const handleViewCredentialModalClose = () => {
    setOpenViewCredentialModal(false);
  };

  const handleEditCredentialModalOpen = (selectedCred: ClientData) => {
    setSelectedCred(selectedCred);
    setOpenEditCredentialModal(true);
  };

  const handleEditCredentialModalClose = () => {
    setOpenEditCredentialModal(false);
  };

  const handleComplete = (response: MutationResponse) => {
    isM2m ? refetchM2MCreds() : refetchClientCreds();
    handleEditCredentialModalClose();
    handleViewCredentialModalClose();
    onCompleted?.current(response);
  };

  const isSelected = (id: number) => selected === id;

  const sortedRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)), [order, orderBy, rows]);
  const slicedRows =
    rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, backgroundColor: theme.palette.background.default }}>
        <StyledTableContainer>
          <Table
            id={'table-creds'}
            data-testid={'table-creds'}
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody id={`tbody-${isM2m ? 'm2m' : 'cc'}`} data-testid={`tbody-${isM2m ? 'm2m' : 'cc'}`}>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1} align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid="loader">
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : rows.length > 0 ? (
                slicedRows.map((row, index) => {
                  const isItemSelected = isSelected(row.index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      id={`tr-${isM2m ? 'm2m' : 'cc'}-${getIdFragment(row.name)}-${row.index}`}
                      hover
                      onClick={(event) => handleClick(event, row.index)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        sx={{ maxWidth: '200px', overflow: 'hidden' }}
                      >
                        <Button
                          id={`btn-view-${isM2m ? 'm2m' : 'cc'}-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleViewCredentialModalOpen(row)}
                          sx={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            padding: '0px',
                            margin: '0px',
                            textTransform: 'none',
                            display: 'block',
                            textAlign: 'left',
                          }}
                        >
                          {row.name}
                        </Button>
                      </TableCell>
                      <TableCell
                        id={`td-${isM2m ? 'm2m' : 'cc'}-client-id-${getIdFragment(row.name)}-${row.index}`}
                        sx={{ paddingY: '0px', maxWidth: '275px', width: '275px' }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, overflow: 'hidden' }}>
                            <MaskedTextField
                              value={row.clientId}
                              id={`show-client-id-${getIdFragment(row.name)}-${row.index}`}
                              data-testid={`show-client-id-${getIdFragment(row.name)}-${row.index}`}
                              fixedWidth={true}
                              truncate={true}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flex: 1,
                                overflow: 'hidden',
                                paddingRight: '12px',
                              }}
                              primaryTypographyProps={{
                                ...theme.typography.body2,
                                flex: 1,
                              }}
                            />
                          </Box>
                          <CopyButton
                            id={`btn-copy-${isM2m ? 'm2m' : 'cc'}-client-id-${getIdFragment(row.name)}-${row.index}`}
                            data={row.clientId}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell
                        id={`td-${isM2m ? 'm2m' : 'cc'}-client-secret-${getIdFragment(row.name)}-${row.index}`}
                        sx={{ paddingY: '0px', maxWidth: '325px', width: '325px' }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, overflow: 'hidden' }}>
                            <MaskedTextField
                              value={row.clientSecret}
                              id={`show-client-secret-${getIdFragment(row.name)}-${row.index}`}
                              data-testid={`show-client-secret-${getIdFragment(row.name)}-${row.index}`}
                              fixedWidth={true}
                              truncate={true}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flex: 1,
                                overflow: 'hidden',
                                paddingRight: '12px',
                              }}
                              primaryTypographyProps={{
                                ...theme.typography.body2,
                                flex: 1,
                              }}
                            />
                          </Box>
                          <CopyButton
                            id={`btn-copy-${isM2m ? 'm2m' : 'cc'}-client-secret-${getIdFragment(row.name)}-${
                              row.index
                            }`}
                            data={row.clientSecret}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell id={`td-${isM2m ? 'm2m' : 'cc'}-created-at-${getIdFragment(row.name)}-${row.index}`}>
                        {formatDate(row.createdAt)}
                      </TableCell>

                      <TableCell>
                        <Button
                          id={`btn-edit-${isM2m ? 'm2m' : 'cc'}-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleEditCredentialModalOpen(row)}
                          sx={{
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0px',
                            padding: '0px',
                          }}
                        >
                          <Edit />
                          <Typography variant={'button'} sx={{ marginLeft: '5px' }}>
                            Edit
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow id={`tr-${isM2m ? 'm2m' : 'cc'}-none`}>
                  <TableCell id={`td-${isM2m ? 'm2m' : 'cc'}-none`} colSpan={headCells.length + 1} align="center">
                    You currently do not have any clients assigned to this dev resource group
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ position: 'sticky', bottom: 0, left: 0, backgroundColor: 'inherit', zIndex: 1 }}>
            <PaginationFooter
              id="pagination-creds"
              count={sortedRows.length}
              page={page}
              rowsPerPage={rowsPerPage}
              pageChangeHandler={handleChangePage}
              handleLastPage={handleLastPage}
              rowsPerPageChangeHandler={handleChangeRowsPerPage}
            />
          </Box>
        </StyledTableContainer>
      </Paper>
      <ActionModal
        id={'view-credential'}
        open={openViewCredentialModal}
        handleClose={handleViewCredentialModalClose}
        subHeader={`${isM2m ? 'Machine to Machine' : 'User Scope'} Client`}
        title={selectedCred?.name}
        dialogContent={
          selectedCred && (
            <SelectedItemTable
              selected={selectedCred}
              onEditOpen={handleEditCredentialModalOpen}
              excludedKeys={['__typename', 'index', 'devGroupId', 'name']}
              maskedKeys={['clientId', 'clientSecret']}
              clipboardEnabledKeys={[
                'clientId',
                'clientSecret',
                'loginUri',
                'callbackUrls',
                'logoutUrls',
                'allowedWebOrigins',
              ]}
            />
          )
        }
      />
      <ActionModal
        id={'edit-credential'}
        open={openEditCredentialModal}
        handleClose={handleEditCredentialModalClose}
        title={`Edit ${isM2m ? 'Machine to Machine' : 'User Scope'} Client`}
        dialogContent={
          selectedCred &&
          (isM2m ? (
            <EditM2MClientForm
              clientData={selectedCred as M2MClientData}
              integrations={integrations}
              onCompleted={handleComplete}
              onError={onError}
            />
          ) : (
            <EditUserScopeClientForm
              clientData={selectedCred as UserScopeClientData}
              onCompleted={handleComplete}
              onError={onError}
            />
          ))
        }
      />
    </Box>
  );
};

export default ClientsDataTable;
