import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Stack,
  Switch,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { validateIntegrationDescription, validateIntegrationName } from '@yonomi/util-validators';
import { Add, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  addM2MCredsToIntegrationGql,
  addWebhookConfigToIntegrationGql,
  createIntegrationGql,
} from '../../../../helpers/gqlQueries';
import { useRefetch } from '../../../../RefetchContext';
import { MutationResponse, SimpleM2MClientData, SimpleWebhookConfigData } from '../../../../models/DataTypes';
import { trimObjectValues } from '../../../../helpers/dataUtils';
import { StyledChip, StyledTextField } from '../../../common/Theme';
import SubmitButtonWithLoader from '../../../common/SubmitButtonWithLoader';
import InfoToolTip from '../../../common/InfoToolTip';
import { IntegrationsIsPublicInfo } from '../../../infoData/IntegrationsInfo';

const CREATE_INTEGRATION = createIntegrationGql();
const ADD_M2M_CREDS_TO_INTEGRATION = addM2MCredsToIntegrationGql();
const ADD_WEBHOOK_TO_INTEGRATION = addWebhookConfigToIntegrationGql();

interface CreateIntegrationFormProps {
  devGroupId: string;
  allM2mClientData: SimpleM2MClientData[];
  allWebhookData: SimpleWebhookConfigData[];
  openCreateM2mClientHandler: () => void;
  openCreateWebhookHandler: () => void;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
  onError: (hasError: boolean, message: string) => void;
  selectedM2mClients?: SimpleM2MClientData[];
  selectedWebhooks?: SimpleWebhookConfigData[];
}

interface CreateIntegrationFormData {
  name: string;
  description: string;
  isPublic: boolean;
  m2mClients: SimpleM2MClientData[];
  webhooks: SimpleWebhookConfigData[];
}

const CreateIntegrationForm = (props: CreateIntegrationFormProps) => {
  const theme = useTheme();

  //GQL
  const [createIntegration, { error: createIntegrationError, loading: creatingIntegration }] = useMutation(
    CREATE_INTEGRATION
  );
  const { refetchByKey } = useRefetch();
  const refetchIntegrations = () => refetchByKey('listIntegrations');

  const [addM2mToIntegration, { error: addM2mToIntegrationError, loading: addingM2mToIntegration }] = useMutation(
    ADD_M2M_CREDS_TO_INTEGRATION
  );

  const [
    addWebhookToIntegration,
    { error: addWebhookToIntegrationError, loading: addingWebhookToIntegration },
  ] = useMutation(ADD_WEBHOOK_TO_INTEGRATION);

  //States
  const [formData, setFormData] = useState<CreateIntegrationFormData>({
    name: '',
    description: '',
    isPublic: false,
    m2mClients: props.selectedM2mClients || [],
    webhooks: props.selectedWebhooks || [],
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      m2mClients: props.selectedM2mClients
        ? [...prevFormData.m2mClients, ...props.selectedM2mClients]
        : prevFormData.m2mClients,
      webhooks: props.selectedWebhooks ? [...prevFormData.webhooks, ...props.selectedWebhooks] : prevFormData.webhooks,
    }));
  }, [props.selectedM2mClients, props.selectedWebhooks]);

  //Handlers
  const validateForm = (data: CreateIntegrationFormData) => {
    let valid = true;
    const newErrors = {
      name: '',
      description: '',
    };

    try {
      validateIntegrationName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Integration Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    try {
      validateIntegrationDescription(data.description);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Integration Description';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.description = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      isPublic: false,
      m2mClients: [],
      webhooks: [],
    });
    setErrors({
      name: '',
      description: '',
    });
  };

  const handleCompleted = async (data: any) => {
    const integrationName = data?.createIntegration?.name;
    const integrationId = data?.createIntegration?.integrationId;
    const allErrors: string[] = [];

    for (const m2mCred of formData.m2mClients) {
      try {
        await addM2mToIntegration({
          variables: {
            integrationId: integrationId,
            clientId: m2mCred.clientId,
          },
        });
      } catch (error) {
        let errorMessage = `Unable to add M2M Clients to ${integrationName}`;
        if (addM2mToIntegrationError) {
          errorMessage += `: ${addM2mToIntegrationError.message}`;
        } else if (error instanceof Error) {
          errorMessage += `: ${error.message}`;
        }
        allErrors.push(errorMessage);
      }
    }
    for (const webhook of formData.webhooks) {
      try {
        await addWebhookToIntegration({
          variables: {
            integrationId: integrationId,
            webhookConfigId: webhook.webhookConfigId,
          },
        });
      } catch (error) {
        let errorMessage = `Unable to add Webhook to ${integrationName}`;
        if (addWebhookToIntegrationError) {
          errorMessage += `: ${addWebhookToIntegrationError.message}`;
        } else if (error instanceof Error) {
          errorMessage += `: ${error.message}`;
        }
        allErrors.push(errorMessage);
      }
    }

    resetForm();
    refetchIntegrations();

    if (allErrors.length > 0) {
      props.onError(true, allErrors.join(', '));
      return;
    }

    props.onCompleted?.current({
      data: data,
      message: `${data?.createIntegration?.name} Integration created successfully`,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      isPublic: event.target.checked,
    });
  };

  const handleM2mClientSelect = (event: React.SyntheticEvent, value: SimpleM2MClientData[]) => {
    setFormData({
      ...formData,
      m2mClients: value,
    });
  };

  const handleWebhooksSelect = (event: React.SyntheticEvent, value: SimpleWebhookConfigData[]) => {
    setFormData({
      ...formData,
      webhooks: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await createIntegration({
          variables: {
            devGroupId: props.devGroupId,
            name: trimmedData.name,
            description: trimmedData.description,
            isPublic: formData.isPublic,
          },
          onCompleted: (data) => handleCompleted(data),
        });
      } catch (error) {
        let errorMessage = `Failed to create Integration`;
        if (createIntegrationError) {
          errorMessage = `${errorMessage}: ${createIntegrationError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const isFormIncomplete: boolean = formData.name.trim() === '' || formData.description.trim() === '';

  const isLoading = creatingIntegration || addingM2mToIntegration || addingWebhookToIntegration;

  return (
    <form id={'form-create-integration'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          fullWidth
          autoComplete="off"
          id="input-create-integration-name"
          label="Name"
          name="name"
          type="text"
          onChange={handleInputChange}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}

        <StyledTextField
          required
          autoComplete="off"
          id="input-create-integration-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <Stack direction={'row'} alignItems={'center'}>
          <Autocomplete
            sx={{ maxWidth: '350px' }}
            multiple
            value={formData.m2mClients}
            id="input-create-integration-m2m-creds"
            data-testid="m2m-creds-autocomplete"
            options={props.allM2mClientData}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
            onChange={handleM2mClientSelect}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                label="Machine to Machine Clients"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <StyledChip size={'small'} {...getTagProps({ index })} label={option.name} />
              ));
            }}
            noOptionsText={
              <Typography
                variant={'body1'}
                id="text-create-integration-no-m2m-creds"
                data-testid="text-create-integration-no-m2m-creds"
                sx={{ color: theme.palette.grey[400] }}
              >
                There are no Machine to Machine Clients
              </Typography>
            }
            PaperComponent={({ children }) => {
              return (
                <Paper>
                  {children}
                  <Button
                    id="btn-create-integration-add-m2m-cred"
                    data-testid="btn-create-integration-add-m2m-cred"
                    color="primary"
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      color: theme.palette.primary.main,
                      display: 'flex',
                      alignItems: 'center',
                      pl: '24px',
                    }}
                    onMouseDown={() => {
                      props.openCreateM2mClientHandler();
                    }}
                  >
                    <Add sx={{ mr: '8px' }} /> Add New Machine to Machine Client
                  </Button>
                </Paper>
              );
            }}
          />
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <Autocomplete
            sx={{ maxWidth: '350px' }}
            multiple
            value={formData.webhooks}
            id="input-create-integration-webhooks"
            data-testid="webhook-autocomplete"
            options={props.allWebhookData}
            isOptionEqualToValue={(option, value) => option.webhookConfigId === value.webhookConfigId}
            disableCloseOnSelect
            onChange={handleWebhooksSelect}
            getOptionLabel={(option) => option.name}
            noOptionsText={
              <Typography
                variant={'body1'}
                id="text-create-integration-no-webhooks"
                data-testid="text-create-integration-no-webhooks"
                sx={{ color: theme.palette.grey[400] }}
              >
                There are no Webhooks
              </Typography>
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                label="Webhooks"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <StyledChip size={'small'} {...getTagProps({ index })} label={option.name} />
              ));
            }}
            PaperComponent={({ children }) => {
              return (
                <Paper>
                  {children}
                  <Button
                    id="btn-create-integration-add-webhook"
                    data-testid="btn-create-integration-add-webhook"
                    color="primary"
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                      color: theme.palette.primary.main,
                      display: 'flex',
                      alignItems: 'center',
                      pl: '24px',
                    }}
                    onMouseDown={() => {
                      props.openCreateWebhookHandler();
                    }}
                  >
                    <Add sx={{ mr: '8px' }} /> Add New Webhook
                  </Button>
                </Paper>
              );
            }}
          />
        </Stack>

        <Stack sx={{ marginTop: '16px' }} direction={'row'} alignItems={'center'}>
          <FormControlLabel
            sx={{ marginLeft: '0px', marginRight: '8px' }}
            control={
              <Switch
                id={'input-create-integration-is-public'}
                checked={formData.isPublic}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'make public' }}
              />
            }
            label="Make public"
          />
          <InfoToolTip
            id={'tooltip-create-integration-is-public'}
            isOffColor={true}
            placement="right"
            title={<IntegrationsIsPublicInfo />}
          />
        </Stack>

        <SubmitButtonWithLoader
          id={'btn-create-integration-submit'}
          disabled={isFormIncomplete || isLoading}
          label={'Create Integration'}
          loading={isLoading}
        />
      </FormControl>
    </form>
  );
};

export default CreateIntegrationForm;
