import IconButton from '@mui/material/IconButton';
import { ContentCopy } from '@mui/icons-material';
import { StyledTooltip } from './Theme';
import * as React from 'react';
import { useState } from 'react';

interface CopyButtonProps {
  id?: string;
  data: string;
  sx?: React.CSSProperties;
  iconSx?: React.CSSProperties;
}

const CopyButton = ({ id, data, sx, iconSx }: CopyButtonProps) => {
  const [tipText, setTipText] = useState('Copy');

  const handleTooltipClose = () => {
    setTipText('Copy');
  };
  const handleCopy = async (value: string) => {
    await navigator.clipboard.writeText(value);
    setTipText('Copied!');
  };

  return (
    <StyledTooltip title={tipText} placement={'right'} onClose={handleTooltipClose} data-testid={id}>
      <IconButton id={id} onClick={() => handleCopy(data)} sx={{ ...sx }}>
        <ContentCopy sx={{ ...iconSx }}></ContentCopy>
      </IconButton>
    </StyledTooltip>
  );
};

export default CopyButton;
