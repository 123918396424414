import Typography from '@mui/material/Typography';
import React from 'react';

const WebOriginUrlsInfo = () => {
  return (
    <Typography variant={'body2'}>
      Allowed Web Origins: List of URLs from where an authorization request using Cross-Origin Authentication, Device
      Flow, and web_message as the response mode can originate from.
      <br />
      <br />
      You can specify multiple valid URLs by comma-separating them. For production environments, verify that the URLs do
      not point to localhost.
      <br />
      <br />
      You can use the star symbol as a wildcard placeholder for subdomains (*.google.com).
      <br />
      <br />
      Paths, query strings, and hash information are not taken into account when validating these URLs (and may, in
      fact, cause the match to fail).
      <br />
      <br />
      You can provide up to 100 URLs in the Allowed Web Origins field.
    </Typography>
  );
};

export default WebOriginUrlsInfo;
