import React, { PropsWithChildren } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '@yonomi/util-config';
import { setContext } from '@apollo/client/link/context';
import { SpanKind, SpanStatusCode, trace } from '@opentelemetry/api';
import { injectContextIntoHeaders } from '@yonomi/util-otel-web';

interface ApolloProviderWithTokenAuthProps {
  children: React.ReactNode;
}

const Auth0ApolloProvider = ({ children }: PropsWithChildren<ApolloProviderWithTokenAuthProps>) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: getConfig('REACT_APP_GRAPHQL_ENDPOINT'),
  });

  const authLink = setContext(async () => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null;
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const createSpanLink = new ApolloLink((operation, forward) => {
    const tracer = trace.getTracer('@apollo/client');
    const span = tracer.startSpan(`GraphQL: ${operation.operationName}`, {
      attributes: {
        'user.id': user?.sub ?? 'undefined',
        'graphql.operation.name': operation.operationName,
        'graphql.operation.type': operation.query.definitions[0].kind,
      },
      kind: SpanKind.CLIENT,
    });

    // Inject context into headers
    const headers: Record<string, string> = {};
    injectContextIntoHeaders(span, headers);
    operation.setContext(({ headers: prevHeaders = {} }) => {
      return {
        span,
        headers: {
          ...prevHeaders,
          ...headers,
        },
      };
    });

    return forward(operation).map((data) => {
      span.setStatus({ code: SpanStatusCode.OK });
      span.end();
      return data;
    });
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const span = operation.getContext().span;
    if (span) {
      span.setStatus({
        code: SpanStatusCode.ERROR,
        message: graphQLErrors?.[0]?.message || networkError?.message || 'Unknown error',
      });

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          span.recordException({
            name: 'GraphQL Error',
            message: `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          });
        });
      }

      if (networkError) {
        span.recordException({
          name: 'Network Error',
          message: `[Network error]: ${networkError.message}`,
        });
      }

      span.end();
    }
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([createSpanLink, errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default Auth0ApolloProvider;
