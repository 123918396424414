import { Autocomplete, Checkbox, FormControl, FormHelperText, Stack } from '@mui/material';
import React, { MutableRefObject, useState } from 'react';
import { useMutation } from '@apollo/client';
import { MutationResponse, SimpleIntegrationData } from '../../../../models/DataTypes';
import { useRefetch } from '../../../../RefetchContext';
import { trimObjectValues } from '../../../../helpers/dataUtils';
import { StyledChip, StyledTextField } from '../../../common/Theme';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import SubmitButtonWithLoader from '../../../common/SubmitButtonWithLoader';
import { addWebhookConfigToIntegrationGql, createWebhookConfigGql } from '../../../../helpers/gqlQueries';
import { getWebhookEventTypeValues, WebhookEventType } from '../../../../models/WebhookEventType';
import {
  validateWebhookConfigDescription,
  validateWebhookConfigEndpoint,
  validateWebhookConfigName,
} from '@yonomi/util-validators';
import InfoToolTip from '../../../common/InfoToolTip';

const CREATE_WEBHOOK_CONFIG = createWebhookConfigGql();
const ADD_WEBHOOK_TO_INTEGRATION = addWebhookConfigToIntegrationGql();

interface CreateWebhookConfigFormProps {
  devGroupId: string;
  integrations: SimpleIntegrationData[];
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
  onError: (hasError: boolean, message: string, forceClose?: boolean) => void;
  isIntegrationFlow?: boolean;
}

interface CreateWebhookConfigFormData {
  name: string;
  description: string;
  endpoint: string;
  eventTypes: string[];
  integrations: SimpleIntegrationData[];
}

const CreateWebhookConfigForm = (props: CreateWebhookConfigFormProps) => {
  //GQL
  const [createWebhookConfig, { error: createWebhookConfigError, loading: creatingWebhookConfig }] = useMutation(
    CREATE_WEBHOOK_CONFIG
  );
  const { refetchByKey } = useRefetch();
  const refetchWebhooks = () => refetchByKey('listWebhooks');
  const [
    addWebhookToIntegration,
    { error: addWebhookToIntegrationError, loading: addingWebhookToIntegration },
  ] = useMutation(ADD_WEBHOOK_TO_INTEGRATION);

  //States
  const [formData, setFormData] = useState<CreateWebhookConfigFormData>({
    name: '',
    description: '',
    endpoint: '',
    eventTypes: [],
    integrations: [],
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
    endpoint: '',
    eventTypes: '',
    integrations: '',
  });

  //Handlers
  const validateForm = (data: CreateWebhookConfigFormData) => {
    let valid = true;
    const newErrors = {
      name: '',
      description: '',
      endpoint: '',
      eventTypes: '',
      integrations: '',
    };

    try {
      validateWebhookConfigName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Webhook Config Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    try {
      validateWebhookConfigDescription(data.description);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Webhook Config Description';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.description = errorMessage;
    }

    try {
      validateWebhookConfigEndpoint(data.endpoint);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Webhook Config Endpoint';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.endpoint = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      endpoint: '',
      eventTypes: [],
      integrations: [],
    });
    setErrors({
      name: '',
      description: '',
      endpoint: '',
      eventTypes: '',
      integrations: '',
    });
  };

  const handleIntegrationAssociation = async (data: any) => {
    const webhookId: string = data?.createWebhookConfig?.webhookConfigId;
    const allErrors: string[] = [];

    for (const integration of formData.integrations) {
      try {
        await addWebhookToIntegration({
          variables: {
            integrationId: integration.integrationId,
            webhookConfigId: webhookId,
          },
        });
      } catch (error) {
        let errorMessage = `Unable to add Webhook Config to ${integration.name}`;
        if (addWebhookToIntegrationError) {
          errorMessage += `: ${addWebhookToIntegrationError.message}`;
        } else if (error instanceof Error) {
          errorMessage += `: ${error.message}`;
        }
        allErrors.push(errorMessage);
      }
    }

    resetForm();
    refetchWebhooks();

    if (allErrors.length > 0) {
      props.onError(true, allErrors.join(', '), true);
      return;
    }

    props.onCompleted?.current({
      data: data,
      message: `${data?.createWebhookConfig?.name} Webhook Config created successfully`,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEventTypeSelect = (event: React.SyntheticEvent, value: string[]) => {
    setFormData({
      ...formData,
      eventTypes: value,
    });
  };

  const handleIntegrationSelect = (event: React.SyntheticEvent, value: SimpleIntegrationData[]) => {
    setFormData({
      ...formData,
      integrations: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await createWebhookConfig({
          variables: {
            devGroupId: props.devGroupId,
            name: trimmedData.name,
            description: trimmedData.description,
            endpoint: trimmedData.endpoint,
            eventTypes: getWebhookEventTypeValues(formData.eventTypes),
          },
          onCompleted: (data) => handleIntegrationAssociation(data),
        });
      } catch (error) {
        let errorMessage = `Failed to create Webhook Config`;
        if (createWebhookConfigError) {
          errorMessage = `${errorMessage}: ${createWebhookConfigError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const isFormIncomplete: boolean =
    formData.name.trim() === '' ||
    formData.description.trim() === '' ||
    formData.endpoint.trim() === '' ||
    formData.eventTypes.length === 0 ||
    (!props.isIntegrationFlow && formData.integrations.length === 0);

  return (
    <form id={'form-create-webhook'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          fullWidth
          autoComplete="off"
          id="input-create-webhook-name"
          label="Name"
          name="name"
          type="text"
          onChange={handleInputChange}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}

        <StyledTextField
          required
          autoComplete="off"
          id="input-create-webhook-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <StyledTextField
          required
          autoComplete="off"
          id="input-create-webhook-endpoint"
          label="Endpoint"
          name="endpoint"
          type="text"
          onChange={handleInputChange}
          value={formData.endpoint}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.endpoint}
        />
        {errors.endpoint && (
          <FormHelperText id="endpoint-helper" error={!!errors.endpoint}>
            {errors.endpoint}
          </FormHelperText>
        )}

        <Stack direction={'row'} alignItems={'center'}>
          <Autocomplete
            sx={{ maxWidth: '350px' }}
            multiple
            value={getWebhookEventTypeValues(formData.eventTypes)}
            id="input-create-webhook-event-types"
            options={Object.values(WebhookEventType)}
            disableCloseOnSelect
            onChange={handleEventTypeSelect}
            getOptionLabel={(option) => option.replace(/_/g, ' ')}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.replace(/_/g, ' ')}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                required
                inputProps={{
                  ...params.inputProps,
                  required: formData.eventTypes.length === 0,
                  readOnly: true,
                }}
                label="Event Types"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <StyledChip size={'small'} {...getTagProps({ index })} label={option.replace(/_/g, ' ')} />
              ));
            }}
            data-testid="event-type-autocomplete"
          />
          <InfoToolTip
            id={'tooltip-create-webhook-event-types'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={'Select the types of events to receive.'}
          />
        </Stack>

        {!props.isIntegrationFlow && (
          <Autocomplete
            sx={{ maxWidth: '350px' }}
            multiple
            value={formData.integrations}
            id="input-create-webhook-integrations"
            options={props.integrations}
            disableCloseOnSelect
            onChange={handleIntegrationSelect}
            isOptionEqualToValue={(option, value) => option.integrationId === value.integrationId}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                required
                inputProps={{
                  ...params.inputProps,
                  required: formData.integrations.length === 0,
                  readOnly: true,
                }}
                label="Integrations"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <StyledChip size={'small'} {...getTagProps({ index })} label={option.name} />
              ));
            }}
            data-testid="integrations-autocomplete"
          />
        )}

        <SubmitButtonWithLoader
          id={'btn-create-webhook-submit'}
          disabled={isFormIncomplete || creatingWebhookConfig || addingWebhookToIntegration}
          label={'Create Webhook Config'}
          loading={creatingWebhookConfig || addingWebhookToIntegration}
        />
      </FormControl>
    </form>
  );
};

export default CreateWebhookConfigForm;
