import { FormControl, FormHelperText, MenuItem, Stack } from '@mui/material';
import { StyledTextField } from '../../../../common/Theme';
import React, { useState } from 'react';
import { deleteAppClientGql, updateClientCredsGql } from '../../../../../helpers/gqlQueries';
import { useMutation } from '@apollo/client';
import {
  validateAllowedWebOrigins,
  validateAppClientDescription,
  validateAppClientName,
  validateCallbackUrls,
  validateLoginUri,
  validateLogoutUrls,
} from '@yonomi/util-validators';
import Box from '@mui/material/Box';
import InfoToolTip from '../../../../common/InfoToolTip';
import AppTypeInfo from '../../../../infoData/AppTypeInfo';
import CallbackUrlsInfo from '../../../../infoData/CallbackUrlsInfo';
import LogoutUrlsInfo from '../../../../infoData/LogoutUrlsInfo';
import { stringToArray, trimObjectValues } from '../../../../../helpers/dataUtils';
import DeleteButton from '../../../../common/DeleteButton';
import SubmitButtonWithLoader from '../../../../common/SubmitButtonWithLoader';
import { MutationResponse, UserScopeClientData } from '../../../../../models/DataTypes';
import { AppTypes, getAppTypeValue } from '../../../../../models/AppTypes';
import WebOriginUrlsInfo from '../../../../infoData/WebOriginUrlsInfo';

const UPDATE_USER_SCOPE_CLIENT = updateClientCredsGql();
const DELETE_CLIENT = deleteAppClientGql();

interface EditUserScopeClientFormProps {
  clientData: UserScopeClientData;
  onCompleted: (response: MutationResponse) => void;
  onError: (hasError: boolean, message: string) => void;
}

interface EditUserScopeClientFormData {
  name: string;
  description: string;
  appType: string;
  loginUri: string;
  callbackUrls: string;
  webOriginUrls: string;
  logoutUrls: string;
}

const EditUserScopeClientForm = (props: EditUserScopeClientFormProps) => {
  //GQL
  const [updateUserScopeClient, { error: updateUserScopeClientError, loading: updatingUserScopeClient }] = useMutation(
    UPDATE_USER_SCOPE_CLIENT
  );
  const [deleteClient, { error: deleteClientError, loading: deletingClient }] = useMutation(DELETE_CLIENT);

  //States
  const [formData, setFormData] = useState<EditUserScopeClientFormData>({
    name: props.clientData.name,
    description: props.clientData.description,
    appType: props.clientData.appType,
    loginUri: props.clientData.loginUri,
    callbackUrls: props.clientData.callbackUrls.join(', '),
    webOriginUrls: props.clientData.allowedWebOrigins ? props.clientData.allowedWebOrigins.join(', ') : '',
    logoutUrls: props.clientData.logoutUrls.join(', '),
  });

  const [errors, setErrors] = useState<EditUserScopeClientFormData>({
    name: '',
    description: '',
    loginUri: '',
    callbackUrls: '',
    webOriginUrls: '',
    logoutUrls: '',
    appType: '',
  });

  //Handlers
  const validateForm = (
    data: EditUserScopeClientFormData,
    callbacksAsArray: string[],
    logoutsAsArray: string[],
    allowedWebOriginUrlsAsArray: string[]
  ) => {
    let valid = true;
    const newErrors: Record<keyof EditUserScopeClientFormData, string> = {
      name: '',
      description: '',
      loginUri: '',
      callbackUrls: '',
      webOriginUrls: '',
      logoutUrls: '',
      appType: '',
    };

    try {
      validateAppClientName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Client Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    if (data.description) {
      try {
        validateAppClientDescription(data.description);
      } catch (error) {
        valid = false;
        let errorMessage = 'Invalid Client Description';
        if (error instanceof Error) errorMessage = error.message;
        newErrors.description = errorMessage;
      }
    }

    if (data.loginUri) {
      try {
        validateLoginUri(data.loginUri);
      } catch (error) {
        valid = false;
        let errorMessage = 'Invalid Client Login URI';
        if (error instanceof Error) errorMessage = error.message;
        newErrors.loginUri = errorMessage;
      }
    }

    try {
      validateCallbackUrls(callbacksAsArray);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Client Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.callbackUrls = errorMessage;
    }
    try {
      validateLogoutUrls(logoutsAsArray);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Client Logout Urls';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.logoutUrls = errorMessage;
    }

    try {
      validateAllowedWebOrigins(allowedWebOriginUrlsAsArray);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Web Origin Urls';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.webOriginUrls = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      loginUri: '',
      callbackUrls: '',
      logoutUrls: '',
      webOriginUrls: '',
      appType: '',
    });
    setErrors({
      name: '',
      description: '',
      loginUri: '',
      callbackUrls: '',
      logoutUrls: '',
      webOriginUrls: '',
      appType: '',
    });
  };

  const handleCompleted = (data: any) => {
    resetForm();
    props.onCompleted({ data, message: `${props.clientData.name} User Scope Clients successfully updated!` });
  };

  const handleDeleteCompleted = (data: any) => {
    props.onCompleted({ data, message: `${props.clientData.name} User Scope Clients successfully deleted!` });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      appType: event.target.value as string,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    const callbacksAsArray: string[] = stringToArray(trimmedData.callbackUrls);
    const logoutsAsArray: string[] = stringToArray(trimmedData.logoutUrls);
    const allowedWebOriginUrlsAsArray: string[] = trimmedData.webOriginUrls
      ? stringToArray(trimmedData.webOriginUrls)
      : [];
    if (validateForm(trimmedData, callbacksAsArray, logoutsAsArray, allowedWebOriginUrlsAsArray)) {
      try {
        props.onError(false, '');
        await updateUserScopeClient({
          variables: {
            clientId: props.clientData.clientId,
            name: trimmedData.name,
            description: trimmedData.description,
            appType: getAppTypeValue(trimmedData.appType) ?? AppTypes.RegularWeb,
            loginUri: trimmedData.loginUri,
            callbackUrls: callbacksAsArray,
            allowedWebOrigins: allowedWebOriginUrlsAsArray,
            logoutUrls: logoutsAsArray,
          },
          onCompleted: (data) => handleCompleted(data),
        });
      } catch (error) {
        let errorMessage = `Failed to update User Scope Client`;
        if (updateUserScopeClientError) {
          errorMessage = `${errorMessage}: ${updateUserScopeClientError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      props.onError(false, '');
      await deleteClient({
        variables: { clientId: props.clientData.clientId },
        onCompleted: (data) => handleDeleteCompleted(data),
      });
    } catch (error) {
      let errorMessage = `Failed to delete ${props.clientData.name} User Scope Client`;
      if (deleteClientError) {
        errorMessage = `${errorMessage}: ${deleteClientError.message}`;
      } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
      props.onError(true, errorMessage);
    }
  };

  const isFormIncomplete: boolean =
    formData.name.trim() === '' ||
    formData.callbackUrls.trim() === '' ||
    formData.logoutUrls.trim() === '' ||
    formData.appType.trim() === '';

  return (
    <form id={'form-edit-client-creds'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          autoComplete="off"
          id="input-edit-client-creds-name"
          label="Name"
          name="name"
          type="text"
          onChange={handleInputChange}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}

        <StyledTextField
          autoComplete="off"
          id="input-edit-client-creds-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <StyledTextField
          autoComplete="off"
          id="input-edit-client-creds-login-uri"
          label="Login URI"
          name="loginUri"
          type="text"
          onChange={handleInputChange}
          value={formData.loginUri}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.loginUri}
        />
        {errors.loginUri && (
          <FormHelperText id="login-uri-helper" error={!!errors.loginUri}>
            {errors.loginUri}
          </FormHelperText>
        )}

        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              required
              autoComplete="off"
              id="input-edit-client-creds-callback-urls"
              label="Callback URLs"
              name="callbackUrls"
              type="text"
              onChange={handleInputChange}
              value={formData.callbackUrls}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.callbackUrls}
            />
            {errors.callbackUrls && (
              <FormHelperText id="callback-urls-helper" error={!!errors.callbackUrls}>
                {errors.callbackUrls}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-edit-client-creds-callback'}
            isOffColor={true}
            sx={{ marginTop: '24px' }}
            placement="right"
            title={<CallbackUrlsInfo />}
          />
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              required
              autoComplete="off"
              id="input-edit-client-creds-logout-urls"
              label="Logout URLs"
              name="logoutUrls"
              type="text"
              onChange={handleInputChange}
              value={formData.logoutUrls}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.logoutUrls}
            />
            {errors.logoutUrls && (
              <FormHelperText id="logout-urls-helper" error={!!errors.logoutUrls}>
                {errors.logoutUrls}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-edit-client-creds-logout'}
            isOffColor={true}
            sx={{ marginTop: '24px' }}
            placement="right"
            title={<LogoutUrlsInfo />}
          />
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              fullWidth
              autoComplete="off"
              id="input-edit-client-creds-allowed-origin-urls"
              label="Allowed Web Origin URLs (optional)"
              name="webOriginUrls"
              type="text"
              onChange={handleInputChange}
              value={formData.webOriginUrls}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.webOriginUrls}
            />
            {errors.webOriginUrls && (
              <FormHelperText id="web-origin-urls-helper" error={!!errors.webOriginUrls}>
                {errors.webOriginUrls}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-edit-client-creds-web-origin-urls'}
            isOffColor={true}
            sx={{ marginTop: '24px' }}
            placement="right"
            title={<WebOriginUrlsInfo />}
          />
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <StyledTextField
            required
            select
            id="input-edit-client-creds-app-type"
            label="Application Type"
            name="appType"
            onChange={handleSelectChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.appType}
            value={formData.appType}
          >
            {Object.values(AppTypes).map((value) => (
              <MenuItem key={value} value={value}>
                {value.replaceAll('_', ' ')}
              </MenuItem>
            ))}
          </StyledTextField>
          <InfoToolTip
            id={'tooltip-edit-client-creds-app-type'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={<AppTypeInfo />}
          />
        </Stack>

        <SubmitButtonWithLoader
          id={'btn-edit-client-creds-submit'}
          disabled={isFormIncomplete || updatingUserScopeClient || deletingClient}
          label={'Save Changes'}
          loading={updatingUserScopeClient}
        />

        <DeleteButton
          id={'btn-delete-client-creds'}
          resourceName={props.clientData.name}
          deleteButtonLabel={'Delete User Scope Client'}
          deleteButtonOnClick={handleDeleteSubmit}
          deleteButtonDisabled={deletingClient || updatingUserScopeClient}
          loading={deletingClient}
        />
      </FormControl>
    </form>
  );
};

export default EditUserScopeClientForm;
