import { SubmitButton } from './Theme';
import Box from '@mui/material/Box';
import Loader from './Loader';
import React from 'react';

interface ButtonWithLoaderProps {
  id?: string;
  disabled: boolean;
  label: string;
  loading: boolean;
}

const SubmitButtonWithLoader = ({ label, disabled, loading, id }: ButtonWithLoaderProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <SubmitButton id={id} disabled={disabled}>
        {label}
      </SubmitButton>
      {loading && (
        <Box id={`loader-submit-btn`} sx={{ marginTop: '24px', marginLeft: '16px' }} data-testid="loader">
          <Loader />
        </Box>
      )}
    </Box>
  );
};

export default SubmitButtonWithLoader;
