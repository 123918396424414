import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonProps } from '@mui/material';

interface LoginButtonProps extends ButtonProps {
  sx?: React.CSSProperties;
}

const LoginButton = ({ sx, id }: LoginButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      id={id}
      variant="contained"
      sx={{ textTransform: 'none', ...sx }}
      onClick={() => loginWithRedirect({ appState: { returnTo: '/developer' } })}
    >
      Log in
    </Button>
  );
};

export default LoginButton;
