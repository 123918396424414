import Typography from '@mui/material/Typography';
import React from 'react';

export const WebhooksInfoText =
  'Yonomi Platform uses webhooks through AWS SNS subscriptions to set up async events that enable real-time communication.\n\nConfirm subscriptions for events to be sent to the specified endpoint. Refer to the AWS documentation for more details.';

const WebhooksInfo = () => {
  return (
    <Typography variant={'body2'}>
      Yonomi Platform uses webhooks through AWS SNS subscriptions to set up async events that enable real-time
      communication.
      <br />
      <br />
      Confirm subscriptions for events to be sent to the specified endpoint. Refer to the AWS documentation for more
      details.
    </Typography>
  );
};

export default WebhooksInfo;
