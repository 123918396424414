import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, Stack, useTheme } from '@mui/material';
import { Spoke } from '@mui/icons-material';
import BoxWithTitle from '../common/BoxWithTitle';
import { useQuery } from '@apollo/client';
import { listDevGroupsGql } from '../../helpers/gqlQueries';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../common/Loader';
import DevResourceGroupMetricsSummary from './devResourceMetrics/DevResourceGroupMetricsSummary';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import SnackBar, { SnackBarState } from '../common/SnackBar';
import { MutationResponse } from '../../models/DataTypes';
import { MainContainer } from '../common/Theme';

const LIST_DEV_GROUPS = listDevGroupsGql();

const GetStartedComponent = () => {
  const theme = useTheme();

  return (
    <Box component="div">
      <Toolbar />
      <Box component="div" display="flex" justifyContent="center">
        <Box component="div" sx={{ maxWidth: '720px' }}>
          <Stack direction="column" alignItems="center" gap={1}>
            <Avatar sx={{ width: '125px', height: '125px', background: theme.palette.grey[600], mb: 5 }}>
              <Spoke sx={{ width: '72px', height: '72px', color: theme.palette.grey[300] }}></Spoke>
            </Avatar>
            <BoxWithTitle
              title={'Get Started with Yonomi Platform'}
              children={
                <Typography variant={'h6'} sx={{ fontWeight: 400 }} align="center" paragraph>
                  Start by creating a <b>Dev Resource Group</b> that groups all the necessary Application Clients and
                  resources. Learn more about Dev Resource Groups and Application Clients in the Quick Start or
                  Documentation accessible from the Side Menu.
                </Typography>
              }
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const DevResources = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(searchParams);
  const devGroupIdParam = searchParams.get('devGroupId') ?? '';
  const devGroupNameParam = searchParams.get('devGroupName') ?? '';
  const organizationIdParam = searchParams.get('organizationId') ?? '';
  const [devGroupId, setDevGroupId] = useState(devGroupIdParam);
  const [devGroupName, setDevGroupName] = useState(devGroupNameParam);
  const [organizationId, setOrganizationId] = useState(organizationIdParam);

  const onCompleted = useRef((response: MutationResponse) => {});

  //Auth0
  const { isAuthenticated } = useAuth0();

  //GQL
  const { data: devGroupListData, error: devGroupListDataError, loading: loadingDevGroupList } = useQuery(
    LIST_DEV_GROUPS,
    {
      skip: !isAuthenticated || devGroupId !== '',
      notifyOnNetworkStatusChange: true,
    }
  );

  //States
  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [error, setError] = useState({ hasError: false, message: '' });

  useEffect(() => {
    if (devGroupListData) {
      setDevGroupId(devGroupListData?.listDevGroups?.edges[0]?.node.devGroupId ?? '');
      setDevGroupName(devGroupListData?.listDevGroups?.edges[0]?.node.name ?? '');
      setOrganizationId(devGroupListData?.listDevGroups?.edges[0]?.node.organizationId ?? '');
    }
  }, [devGroupListData]);

  useEffect(() => {
    if (devGroupId && devGroupName && organizationId) {
      setSearchParams({
        devGroupId: devGroupId,
        devGroupName: devGroupName,
        organizationId: organizationId,
      });
    }
  }, [devGroupId, devGroupName, organizationId, setSearchParams]);

  const handleSnackbarClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const delayedNavigate = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate('/');
  };

  onCompleted.current = (response: MutationResponse) => {
    const data = response.data;
    setSnackBarState({ open: true, message: response.message, severity: 'success' });
    if (data?.deleteDevGroup) {
      delayedNavigate();
    } else {
      if (data?.updateDevGroup) {
        setDevGroupName(data?.updateDevGroup?.name);
        queryParams.set('devGroupName', data?.updateDevGroup?.name);
        setSearchParams(queryParams.toString());
      }
    }
  };

  const handleOnError = (hasError: boolean, message: string) => {
    setError({ hasError, message });
  };

  useEffect(() => {
    if (error.hasError) {
      setSnackBarState({ open: true, message: error.message, severity: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (devGroupListDataError) {
      const errorMessage = devGroupListDataError?.message || 'Error processing request';
      setError({ hasError: true, message: errorMessage });
    }
  }, [devGroupListDataError]);

  const hasDevGroups = (devGroupListData?.listDevGroups?.edges?.length ?? 0) > 0;
  const shouldFetchDevGroup = hasDevGroups || devGroupId !== '';

  return isAuthenticated ? (
    <>
      <MainContainer>
        {loadingDevGroupList ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              flexGrow: 1,
              height: '50vh',
            }}
          >
            <Box id={'loader-dev-resources-panel'} data-testid="loader-dev-resources-panel">
              <Loader />
            </Box>
          </Box>
        ) : shouldFetchDevGroup ? (
          <DevResourceGroupMetricsSummary
            devGroupId={devGroupId}
            devGroupName={devGroupName}
            organizationId={organizationId}
            loading={loadingDevGroupList}
            onCompleted={onCompleted}
            onError={handleOnError}
          />
        ) : (
          <GetStartedComponent />
        )}
        <SnackBar
          id={'notification-snackbar-dev-group-metrics'}
          data-testid={'notification-snackbar-dev-group-metrics'}
          open={snackBarState.open}
          handleClose={handleSnackbarClose}
          message={snackBarState.message}
          severity={snackBarState.severity}
        />
      </MainContainer>
    </>
  ) : null;
};

export default DevResources;
