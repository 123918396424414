/**
 * Creates a debounced function that delays invoking the provided function until after the specified wait time has elapsed
 * since the last time the debounced function was invoked. The debounced function comes with a `cancel` method to cancel
 * delayed invocations.
 *
 * @param func The function to debounce.
 * @param wait The number of milliseconds to delay.
 * @returns A debounced function with a `cancel` method.
 */
export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  const debounced = (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
  debounced.cancel = () => clearTimeout(timeout);
  return debounced;
};
