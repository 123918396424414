import { MutableRefObject, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { getDevResourceCountGql } from '../../../helpers/gqlQueries';
import { useAuth0 } from '@auth0/auth0-react';
import DevGroupTitleBox from '../DevGroupTitleBox';
import { MutationResponse } from '../../../models/DataTypes';
import DevGroupCredentialBox from './DevGroupClientsBox';
import DevGroupMetricBox from './DevGroupMetricBox';
import M2mClientsInfo from '../../infoData/M2mClientsInfo';
import WebhooksInfo from '../../infoData/WebhooksInfo';
import { IntegrationsInfo } from '../../infoData/IntegrationsInfo';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useData } from '../../../DataContext';
import UserScopeClientsInfo from '../../infoData/UserScopeClientsInfo';
import { ContainerToolBar, ContentBox } from '../../common/Theme';

interface DevResourceGroupComponentProps {
  devGroupId: string;
  devGroupName: string;
  organizationId: string;
  loading: boolean;
  onError: (hasError: boolean, message: string) => void;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
}

export interface QueryResponse {
  clientCreds: number;
  m2mCreds: number;
  getIntegrationCount: number;
  getWebhookConfigCount: number;
  getFederationConfigCount: number;
}

const GET_DEV_RESOURCE_COUNT = getDevResourceCountGql();

const DevResourceGroupMetricsSummary = ({
  devGroupId,
  devGroupName,
  organizationId,
  onCompleted,
  onError,
}: DevResourceGroupComponentProps) => {
  const theme = useTheme();

  //Auth0
  const { isAuthenticated } = useAuth0();

  //Data Context
  const { getData } = useData();

  //GQL
  const {
    data: devResGroupData,
    error: devResGroupDataError,
    loading: loadingDevResCountData,
  } = useQuery<QueryResponse>(GET_DEV_RESOURCE_COUNT, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  devGroupName = devGroupName || 'Undefined';

  const devGroupUserScopeClientCount = devResGroupData?.clientCreds.toString() ?? '0';
  const devGroupM2MClientCount = devResGroupData?.m2mCreds.toString() ?? '0';
  const devGroupTotalCredsCount = ((devResGroupData?.clientCreds ?? 0) + (devResGroupData?.m2mCreds ?? 0)).toString();
  const devGroupIntegrationsCount = devResGroupData?.getIntegrationCount?.toString() ?? '0';
  const devGroupWebhooksCount = devResGroupData?.getWebhookConfigCount?.toString() ?? '0';
  const devGroupFedConfigCount = devResGroupData?.getFederationConfigCount?.toString() ?? '0';

  const hasFedMgmtConfigAccess =
    getData('devGroups')?.listDevGroups?.edges?.find((item: any) => item.node.devGroupId === devGroupId)?.node
      ?.federationManagementConfig?.isActive ?? false;

  useEffect(() => {
    if (devResGroupDataError) {
      const errorMessage = devResGroupDataError?.message || 'Error processing request';
      onError(true, errorMessage);
    }
  }, [devResGroupDataError, onError]);

  const itemCount = (sectionName: string, content: string) => {
    return content === '0' ? (
      <Typography variant="body2" align="right" sx={{ color: theme.palette.grey[400] }}>
        You currently do not have any {sectionName} assigned to this dev resource group.
      </Typography>
    ) : (
      <Typography variant="h4">{content}</Typography>
    );
  };

  const fedConfigTopRightItem = hasFedMgmtConfigAccess ? (
    itemCount('Federation Configs', devGroupFedConfigCount)
  ) : (
    <Typography variant="body2" align="right" sx={{ marginTop: '8px', opacity: 0.6 }}>
      Contact your Yonomi representative to request access to Federation Configs
    </Typography>
  );

  return (
    <>
      <ContainerToolBar />
      <Box component="div">
        <DevGroupTitleBox
          devGroupId={devGroupId}
          name={devGroupName}
          organizationId={organizationId}
          onCompleted={onCompleted}
          onError={onError}
          sx={{ marginBottom: '24px' }}
        />
        <ContentBox id="panel-dev-group-metrics" data-testid="panel-dev-group-metrics">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DevGroupMetricBox
                id="panel-dev-group-metrics-integrations"
                data-testid="panel-dev-group-metrics-integrations"
                sectionName="Integrations"
                devGroupId={devGroupId.toString()}
                devGroupName={devGroupName}
                organizationId={organizationId}
                topRightItem={itemCount('Integrations', devGroupIntegrationsCount)}
                loading={loadingDevResCountData}
                tooltip={<IntegrationsInfo />}
              />
              <DevGroupMetricBox
                id="panel-dev-group-metrics-application-clients"
                data-testid="panel-dev-group-metrics-application-clients"
                sectionName="Application Clients"
                devGroupId={devGroupId.toString()}
                devGroupName={devGroupName}
                organizationId={organizationId}
                topRightItem={itemCount('Application Clients', devGroupTotalCredsCount)}
                loading={loadingDevResCountData}
              >
                <DevGroupCredentialBox
                  id="panel-dev-group-metrics-m2m-creds"
                  data-testid="panel-dev-group-metrics-m2m-creds"
                  name="Machine to Machine Clients"
                  itemCount={devGroupM2MClientCount}
                  loading={loadingDevResCountData}
                  tooltip={<M2mClientsInfo />}
                />
                <DevGroupCredentialBox
                  id="panel-dev-group-metrics-client-creds"
                  data-testid="panel-dev-group-metrics-client-creds"
                  name="User Scope Clients"
                  itemCount={devGroupUserScopeClientCount}
                  loading={loadingDevResCountData}
                  tooltip={<UserScopeClientsInfo />}
                />
              </DevGroupMetricBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <DevGroupMetricBox
                id="panel-dev-group-metrics-webhooks"
                data-testid="panel-dev-group-metrics-webhooks"
                sectionName="Webhooks"
                devGroupId={devGroupId.toString()}
                devGroupName={devGroupName}
                organizationId={organizationId}
                topRightItem={itemCount('Webhooks', devGroupWebhooksCount)}
                loading={loadingDevResCountData}
                tooltip={<WebhooksInfo />}
              />
              <DevGroupMetricBox
                id="panel-dev-group-metrics-fed-configs"
                data-testid="panel-dev-group-metrics-fed-configs"
                sectionName="Federation Configs"
                devGroupId={devGroupId.toString()}
                devGroupName={devGroupName}
                organizationId={organizationId}
                topRightItem={fedConfigTopRightItem}
                restrictedAccess={!hasFedMgmtConfigAccess}
                loading={loadingDevResCountData}
                tooltip={
                  <Typography variant={'body2'}>
                    For users not within the Allegion user pool a Federation Config will need to be set up.
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ContentBox>
      </Box>
    </>
  );
};

export default DevResourceGroupMetricsSummary;
