import { Avatar, BoxProps, Button, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { Edit, Grain } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { MutableRefObject, useState } from 'react';
import ActionModal from '../common/ActionModal';
import EditDevResourceForm from './forms/EditDevResourceForm';
import { useRefetch } from '../../RefetchContext';
import { MutationResponse } from '../../models/DataTypes';
import InfoToolTip from '../common/InfoToolTip';
import CopyButton from '../common/CopyButton';
import { OrganizationIdInfoText } from '../infoData/OrganizationIdInfo';
import { GraphqlApiInfoText } from '../infoData/GraphqlApiInfo';

const GRAPHQL_API_ENDPOINT = process.env.REACT_APP_GRAPHQL_API_ENDPOINT ?? 'https://services.yonomi.cloud/graphql';

interface DevGroupTitleBoxProps {
  devGroupId: string;
  name: string;
  organizationId: string;
  sx?: React.CSSProperties;
  onError: (hasError: boolean, message: string) => void;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
}

const ContainerBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: 'auto',
  maxWidth: '1120px',
  height: '163px',
  maxHeight: '163px',
  margin: 'auto',
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[800],
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down(1080)]: {
    flexDirection: 'column',
    height: 'auto',
    maxHeight: 'none',
  },
  [theme.breakpoints.between('sm', 700)]: {
    // to prevent graphql api url from hiding
    minWidth: '350px',
  },
}));

const DevGroupTitleBox = ({ devGroupId, name, organizationId, sx, onError, onCompleted }: DevGroupTitleBoxProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:1080px)');

  //GQL
  const { refetchByKey } = useRefetch();
  const triggerRefetch = () => refetchByKey('listDevGroups');

  //States
  const [editDevGrpOpen, setEditDevGroupOpen] = useState(false);

  //Handlers
  const handleEditOpen = () => {
    setEditDevGroupOpen(true);
  };

  const handleEditClose = () => {
    setEditDevGroupOpen(false);
  };

  const handleUpdateComplete = (data: any) => {
    triggerRefetch();
    handleEditClose();
    onCompleted?.current({ data, message: `${name} Dev Resource Group successfully updated!` });
  };

  const handleDeleteComplete = (data: any) => {
    triggerRefetch();
    onCompleted?.current({ data, message: `${name} Dev Resource Group successfully deleted!` });
  };

  return (
    <ContainerBox
      component={'div'}
      id={'panel-dev-group'}
      sx={{
        ...sx,
      }}
    >
      {/* Left-side Avatar */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: isSmallScreen ? theme.spacing(2) : 0,
        }}
      >
        <Avatar
          variant={'square'}
          sx={{ width: '133px', height: '133px', borderRadius: '8px', background: theme.palette.grey[600] }}
        >
          <Grain sx={{ width: '72px', height: '72px', color: theme.palette.grey[500] }} />
        </Avatar>
      </Box>

      {/* Center container */}
      <Box
        sx={{
          flexGrow: 1,
          minWidth: '200px',
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          marginX: 2,
          marginBottom: isSmallScreen ? theme.spacing(2) : 0,
          padding: 2,
          backgroundColor: theme.palette.grey[700],
          borderRadius: '8px',
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{ alignItems: 'flex-start', flexGrow: 1, overflow: 'hidden', minWidth: '145px' }}
          >
            <Typography
              align="left"
              variant={'subtitle2'}
              sx={{ opacity: 0.6, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
              paragraph
            >
              Dev Resource Group
            </Typography>
            <Typography
              variant="h4"
              align="left"
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
              paragraph
              noWrap
            >
              {name}
            </Typography>
          </Stack>
          <Button
            id={'btn-edit-dev-group'}
            onClick={handleEditOpen}
            sx={{
              textTransform: 'none',
              color: theme.palette.text.primary,
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'flex-start',
              marginTop: '-16px',
            }}
          >
            <Edit />
            <Typography variant={'button'} sx={{ marginLeft: '5px' }}>
              Edit
            </Typography>
          </Button>
        </Box>
      </Box>

      {/* Right-side container */}
      <Box
        sx={{
          width: isSmallScreen ? '100%' : '350px', // Adjust width for small screens
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          paddingY: 1,
          paddingX: 2,
          height: '100%',
        }}
      >
        <Stack direction="column" spacing={1.5}>
          <Stack direction="column" spacing={0.2}>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }} align="left" paragraph>
              IDP Auth-Org Parameter
              <InfoToolTip
                id={'tooltip-organization-id'}
                isOffColor={true}
                title={OrganizationIdInfoText}
                sx={{ width: '24px', height: '24px', marginLeft: 1 }}
                iconSx={{ width: '20px', height: '20px' }}
              />
            </Typography>
            <Typography
              id="text-dev-group-title-organization-id"
              variant="button"
              align="left"
              paragraph
              noWrap
              sx={{ fontWeight: 700 }}
            >
              {organizationId}
              <CopyButton
                id="btn-copy-dev-group-title-organization-id"
                data={organizationId}
                sx={{ width: '28px', height: '28px', marginLeft: 1 }}
                iconSx={{ width: '24px', height: '24px' }}
              />
            </Typography>
          </Stack>
          <Stack direction="column" spacing={0.2}>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }} align="left" paragraph>
              GraphQL API Endpoint
              <InfoToolTip
                id={'tooltip-graphql-api-endpoint'}
                isOffColor={true}
                title={GraphqlApiInfoText}
                sx={{ width: '24px', height: '24px', marginLeft: 1 }}
                iconSx={{ width: '20px', height: '20px' }}
              />
            </Typography>
            <Typography
              id="text-dev-group-title-graphql-endpoint"
              variant="button"
              align="left"
              paragraph
              noWrap
              sx={{ fontWeight: 700 }}
            >
              {GRAPHQL_API_ENDPOINT}
              <CopyButton
                id="btn-copy-dev-group-title-graphql-endpoint"
                data={GRAPHQL_API_ENDPOINT}
                sx={{ width: '28px', height: '28px', marginLeft: 1 }}
                iconSx={{ width: '24px', height: '24px' }}
              />
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <ActionModal
        id={'edit-dev-group'}
        open={editDevGrpOpen}
        handleClose={handleEditClose}
        title="Edit Dev Resource Group"
        dialogContent={
          <EditDevResourceForm
            devGroupId={devGroupId}
            devGroupName={name}
            onCompleted={handleUpdateComplete}
            onDeleteCompleted={handleDeleteComplete}
            onError={onError}
          />
        }
      />
    </ContainerBox>
  );
};

export default DevGroupTitleBox;
