import { Box, Button, DialogActions, DialogContent, DialogContentText, Divider, useTheme } from '@mui/material';
import React from 'react';
import Loader from './Loader';

interface ConfirmationFormProps {
  id?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  message?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  confirmButtonStyle?: React.CSSProperties;
  cancelButtonStyle?: React.CSSProperties;
  mainContent?: React.ReactNode;
  actionsSx?: React.CSSProperties;
  contentSx?: React.CSSProperties;
  loading?: boolean;
}

export const ConfirmationForm = ({
  id,
  handleClose,
  handleConfirm,
  message,
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  confirmButtonStyle,
  cancelButtonStyle,
  mainContent: customContent,
  actionsSx,
  contentSx,
  loading = false,
}: ConfirmationFormProps) => {
  const theme = useTheme();
  return (
    <>
      <DialogContent id={id} sx={{ padding: '32px 24px', ...contentSx }}>
        {loading ? (
          <Box
            id={'loader-delete-btn'}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            data-testid="loader-delete-btn"
          >
            <Loader />
          </Box>
        ) : customContent ? (
          customContent
        ) : (
          <DialogContentText>{message}</DialogContentText>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'flex-end', padding: '16px 24px', ...actionsSx }}>
        <Button
          id={`btn-${id}-cancel`}
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            padding: '12px 16px',
            borderColor: theme.palette.grey[700],
            color: theme.palette.primary.main,
            ...cancelButtonStyle,
          }}
          color="primary"
        >
          {cancelButtonLabel}
        </Button>
        <Button
          id={`btn-${id}-confirm`}
          onClick={handleConfirm}
          variant="contained"
          sx={{ textTransform: 'none', padding: '12px 16px', ...confirmButtonStyle }}
          autoFocus
          disabled={loading}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationForm;
