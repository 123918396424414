import Typography from '@mui/material/Typography';
import React from 'react';

const IntegrationsIsPublicInfo = () => {
  return (
    <Typography variant={'body2'}>
      YP Developer Portal Admins can designate integrations as either searchable (public) or not searchable (private).
      <br />
      <br />
      Public integrations are discoverable and configured at the organization level.
    </Typography>
  );
};

const IntegrationsInfo = () => {
  return (
    <Typography variant={'body2'}>
      An integration is a container for managing M2M clients and webhooks.
      <br />
      <br />
      YP Developer Portal Admins can designate integrations as either searchable (public) or not searchable (private).
      <br />
      <br />
      Public integrations are discoverable and configured at the organization level.
    </Typography>
  );
};

export { IntegrationsIsPublicInfo, IntegrationsInfo };
