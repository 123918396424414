import { Button, useTheme } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import DevGroupTitleBox from '../DevGroupTitleBox';
import Typography from '@mui/material/Typography';
import InfoToolTip from '../../common/InfoToolTip';
import { Add } from '@mui/icons-material';
import SnackBar, { SnackBarState } from '../../common/SnackBar';
import ActionModal from '../../common/ActionModal';
import { MutationResponse, SimpleIntegrationData, WebhookConfigData } from '../../../models/DataTypes';
import { listIntegrationsGql, listWebhookConfigsGql } from '../../../helpers/gqlQueries';
import { useRefetch } from '../../../RefetchContext';
import { useQuery } from '@apollo/client';
import WebhooksTable from './tables/WebhooksTable';
import CreateWebhookConfigForm from './forms/CreateWebhookConfigForm';
import WebhooksInfo from '../../infoData/WebhooksInfo';
import { ContainerToolBar, ContentBox, MainContainer, StyledTableTitleRow } from '../../common/Theme';
import ConfirmationForm from '../../common/ConfirmationForm';

const LIST_WEBHOOKS = listWebhookConfigsGql();
const LIST_INTEGRATIONS = listIntegrationsGql();
const QUERY_MAX_FETCH_COUNT = 30;
const Webhooks = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(searchParams);
  const devGroupId = searchParams.get('devGroupId') ?? '';
  const devGroupNameParam = searchParams.get('devGroupName') ?? '';
  const organizationId = searchParams.get('organizationId') || '';

  const onCompleted = useRef((response: MutationResponse) => {});

  //Auth0
  const { isAuthenticated } = useAuth0();

  //GQL
  const { registerRefetch } = useRefetch();
  const {
    data: webhooksData,
    error: listWebhooksError,
    refetch: refetchWebhooks,
    loading: loadingWebhooks,
    fetchMore: fetchMoreWebhooks,
  } = useQuery(LIST_WEBHOOKS, {
    skip: !isAuthenticated || !devGroupId,
    variables: { devGroupId, first: QUERY_MAX_FETCH_COUNT },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  registerRefetch('listWebhooks', refetchWebhooks);

  const { data: integrationsData, error: listIntegrationsError, loading: loadingIntegrations } = useQuery(
    LIST_INTEGRATIONS,
    {
      skip: !isAuthenticated || !devGroupId,
      variables: { devGroupId, first: QUERY_MAX_FETCH_COUNT },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  //States
  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [error, setError] = useState({ hasError: false, message: '' });
  const [devGroupName, setDevGroupName] = useState(devGroupNameParam);
  const [createWebhookConfig, setCreateWebhookConfig] = useState(false);
  const [noIntegrationsWarningOpen, setNoIntegrationsWarningOpen] = useState(false);
  const [lastWebhookCursor, setLastWebhookCursor] = useState('');

  //Handlers

  const handleSnackbarClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const handleCreateWebhookConfigOpen = () => {
    if (integrations.length > 0) {
      setCreateWebhookConfig(true);
    } else {
      handleNoIntegrationsWarningOpen();
    }
  };

  const handleNoIntegrationsWarningOpen = () => {
    setNoIntegrationsWarningOpen(true);
  };

  const handleCreateWebhookConfigClose = () => {
    setCreateWebhookConfig(false);
  };

  const handleNoIntegrationsWarningClose = () => {
    setNoIntegrationsWarningOpen(false);
  };

  const navigateToIntegration = () => {
    navigate('/integrations?' + searchParams.toString());
  };

  const delayedNavigate = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate('/developer');
  };

  onCompleted.current = (response: MutationResponse) => {
    const data = response.data;
    setSnackBarState({ open: true, message: response.message, severity: 'success' });
    if (data?.deleteDevGroup) {
      delayedNavigate();
    } else {
      if (data?.updateDevGroup) {
        setDevGroupName(data?.updateDevGroup?.name);
        queryParams.set('devGroupName', data?.updateDevGroup?.name);
        setSearchParams(queryParams.toString());
      } else if (data?.createWebhookConfig) {
        setCreateWebhookConfig(false);
      }
    }
  };

  const handleFetchMoreWebhooks = () => {
    const hasNextPage = webhooksData?.listWebhookConfigs?.pageInfo?.hasNextPage;
    if (hasNextPage) {
      fetchMoreWebhooks({
        variables: { first: QUERY_MAX_FETCH_COUNT, after: lastWebhookCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prevResult;
          }
          return {
            listWebhookConfigs: {
              ...fetchMoreResult.listWebhookConfigs,
              edges: [...prevResult.listWebhookConfigs.edges, ...fetchMoreResult.listWebhookConfigs.edges],
            },
          };
        },
      });
    }
  };

  const handleOnError = (hasError: boolean, message: string) => {
    setError({ hasError, message });
  };

  useEffect(() => {
    if (error.hasError) {
      setSnackBarState({ open: true, message: error.message, severity: 'error' });
    }
  }, [error]);

  useEffect(() => {
    if (listWebhooksError || listIntegrationsError) {
      const errorMessage = listWebhooksError?.message || listIntegrationsError?.message || 'Error processing request';
      setError({ hasError: true, message: errorMessage });
    }
  }, [listWebhooksError, listIntegrationsError]);

  useEffect(() => {
    if (webhooksData) {
      setLastWebhookCursor(webhooksData?.listWebhookConfigs?.pageInfo?.endCursor ?? '');
    }
  }, [webhooksData]);

  const webhooks: WebhookConfigData[] = (webhooksData?.listWebhookConfigs?.edges || []).map(
    (edge: any, index: number) => ({
      webhookConfigId: edge.node.webhookConfigId,
      devGroupId: edge.node.devGroupId,
      name: edge.node.name,
      description: edge.node.description,
      endpoint: edge.node.endpoint,
      eventTypes: edge.node.eventTypes,
      createdAt: edge.node.createdAt,
      updatedAt: edge.node.updatedAt,
      devGroup: devGroupName,
      integrations: edge.node.webhookConfigIntegrationAssoc.edges.map((edge: any) => {
        return { name: edge.node.integration.name, integrationId: edge.node.integration.integrationId };
      }),
      index: index,
    })
  );

  const integrations: SimpleIntegrationData[] = (integrationsData?.listIntegrations?.edges || []).map((edge: any) => ({
    integrationId: edge.node.integrationId,
    name: edge.node.name,
  }));

  return isAuthenticated ? (
    <>
      <MainContainer>
        <ContainerToolBar />
        <Box component="div">
          <DevGroupTitleBox
            devGroupId={devGroupId}
            name={devGroupName}
            organizationId={organizationId}
            onCompleted={onCompleted}
            onError={handleOnError}
          />
          <ContentBox id={'webhooks-section'}>
            <Box id={'webhook-table-section'}>
              <StyledTableTitleRow direction="row" spacing={1} justifyContent="space-between" id={'cc-header-row'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" align="left" paragraph sx={{ marginBottom: 0 }}>
                    Webhooks
                  </Typography>
                  <InfoToolTip id={'tooltip-webhooks'} placement="right" title={<WebhooksInfo />} />
                </Box>
                {!loadingWebhooks && !loadingIntegrations && (
                  <Button
                    id={'btn-add-webhook'}
                    variant="contained"
                    onClick={handleCreateWebhookConfigOpen}
                    sx={{ paddingLeft: '10px', textTransform: 'none', height: '36px' }}
                  >
                    <Add
                      sx={{
                        width: '20px',
                        height: '20px',
                        marginRight: '8px',
                      }}
                    ></Add>
                    <Typography variant="button" sx={{ paddingTop: '2px' }}>
                      Add Webhook
                    </Typography>
                  </Button>
                )}
              </StyledTableTitleRow>
              <Box>
                <WebhooksTable
                  rows={webhooks}
                  integrations={integrations}
                  fetchMore={handleFetchMoreWebhooks}
                  onCompleted={onCompleted}
                  onError={handleOnError}
                  loading={loadingWebhooks}
                />
              </Box>
            </Box>
          </ContentBox>
          <SnackBar
            id={'notification-snackbar-webhooks'}
            open={snackBarState.open}
            handleClose={handleSnackbarClose}
            message={snackBarState.message}
            severity={snackBarState.severity}
          />
        </Box>
      </MainContainer>
      <ActionModal
        id={'create-webhook-config'}
        open={createWebhookConfig}
        handleClose={handleCreateWebhookConfigClose}
        title={'Add Webhook'}
        dialogContent={
          <CreateWebhookConfigForm
            devGroupId={devGroupId}
            integrations={integrations}
            onCompleted={onCompleted}
            onError={handleOnError}
          />
        }
      ></ActionModal>
      <ActionModal
        id={'no-integrations-warning'}
        open={noIntegrationsWarningOpen}
        handleClose={handleNoIntegrationsWarningClose}
        title={'Integration Required to Proceed'}
        titleSx={{
          ...theme.typography.h5,
          padding: '24px 24px',
        }}
        paperSx={{ padding: '0px 0px', borderRadius: '8px' }}
        showTitleDivider
        showCloseButton={false}
        dialogContent={
          <ConfirmationForm
            id={'modal-no-integrations-warning'}
            handleClose={handleNoIntegrationsWarningClose}
            handleConfirm={navigateToIntegration}
            message={`An integration is required to create a Webhook.\n\nClick "OK" below to go to the Integrations page and choose "Add Integration". Once you've added an integration you can create a Webhook.`}
            confirmButtonLabel={'Go to Integrations'}
          />
        }
      />
    </>
  ) : null;
};

export default Webhooks;
