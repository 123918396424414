import { Autocomplete, Checkbox, FormControl, FormHelperText, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  validateWebhookConfigDescription,
  validateWebhookConfigEndpoint,
  validateWebhookConfigName,
} from '@yonomi/util-validators';
import {
  addWebhookConfigToIntegrationGql,
  deleteWebhookConfigGql,
  removeWebhookConfigFromIntegrationGql,
  updateWebhookConfigGql,
} from '../../../../helpers/gqlQueries';
import { MutationResponse, SimpleIntegrationData, WebhookConfigData } from '../../../../models/DataTypes';
import { trimObjectValues } from '../../../../helpers/dataUtils';
import { getWebhookEventTypeValues, WebhookEventType } from '../../../../models/WebhookEventType';
import DeleteButton from '../../../common/DeleteButton';
import SubmitButtonWithLoader from '../../../common/SubmitButtonWithLoader';
import { StyledChip, StyledTextField } from '../../../common/Theme';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import InfoToolTip from '../../../common/InfoToolTip';

const UPDATE_WEBHOOK_CONFIG = updateWebhookConfigGql();
const DELETE_WEBHOOK_CONFIG = deleteWebhookConfigGql();
const ADD_WEBHOOK_TO_INTEGRATION = addWebhookConfigToIntegrationGql();
const REMOVE_WEBHOOK_FROM_INTEGRATION = removeWebhookConfigFromIntegrationGql();

interface EditWebhookConfigFormProps {
  webhookData: WebhookConfigData;
  integrations: SimpleIntegrationData[];
  onCompleted: (response: MutationResponse) => void;
  onError: (hasError: boolean, message: string) => void;
}

interface EditWebhookConfigFormData {
  name: string;
  description: string;
  endpoint: string;
  eventTypes: string[];
  integrations: SimpleIntegrationData[];
}

const EditWebhookConfigForm = (props: EditWebhookConfigFormProps) => {
  //GQL
  const [updateWebhookConfig, { error: updateWebhookConfigError, loading: updatingWebhookConfig }] = useMutation(
    UPDATE_WEBHOOK_CONFIG
  );
  const [deleteWebhookConfig, { error: deleteWebhookConfigError, loading: deletingWebhookConfig }] = useMutation(
    DELETE_WEBHOOK_CONFIG
  );
  const [
    addWebhookToIntegration,
    { error: addWebhookToIntegrationError, loading: addingWebhookToIntegration },
  ] = useMutation(ADD_WEBHOOK_TO_INTEGRATION);
  const [
    removeWebhookFromIntegration,
    { error: removeWebhookFromIntegrationError, loading: removingWebhookFromIntegration },
  ] = useMutation(REMOVE_WEBHOOK_FROM_INTEGRATION);

  //States
  const [formData, setFormData] = useState<EditWebhookConfigFormData>({
    name: props.webhookData.name,
    description: props.webhookData.description,
    endpoint: props.webhookData.endpoint,
    eventTypes: props.webhookData.eventTypes,
    integrations: props.webhookData.integrations,
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
    endpoint: '',
    eventTypes: '',
    integrations: '',
  });

  //Handlers
  const validateForm = (data: EditWebhookConfigFormData) => {
    let valid = true;
    const newErrors = {
      name: '',
      description: '',
      endpoint: '',
      eventTypes: '',
      integrations: '',
    };

    try {
      validateWebhookConfigName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Webhook Config Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    try {
      validateWebhookConfigDescription(data.description);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Webhook Config Description';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.description = errorMessage;
    }

    try {
      validateWebhookConfigEndpoint(data.endpoint);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Webhook Config Endpoint';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.endpoint = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      endpoint: '',
      eventTypes: [],
      integrations: [],
    });
    setErrors({
      name: '',
      description: '',
      endpoint: '',
      eventTypes: '',
      integrations: '',
    });
  };

  const handleIntegrationAssociation = async (data: any) => {
    const webhookId: string = data?.updateWebhookConfig?.webhookConfigId;
    const allErrors: string[] = [];

    const currentIntegrations = new Set(props.webhookData.integrations.map(({ integrationId }) => integrationId));
    const updatedIntegrations = new Set(formData.integrations.map(({ integrationId }) => integrationId));

    try {
      //For any new integrations, add the webhook to the integration
      for (const integration of formData.integrations) {
        if (!currentIntegrations.has(integration.integrationId)) {
          try {
            await addWebhookToIntegration({
              variables: {
                integrationId: integration.integrationId,
                webhookConfigId: webhookId,
              },
            });
          } catch (error) {
            let errorMessage = `Unable to add Webhook Config to ${integration.name}`;
            if (addWebhookToIntegrationError) {
              errorMessage += `: ${addWebhookToIntegrationError.message}`;
            } else if (error instanceof Error) {
              errorMessage += `: ${error.message}`;
            }
            allErrors.push(errorMessage);
          }
        }
      }
      //For any integrations that were removed, remove the webhook from the integration
      for (const integration of props.webhookData.integrations) {
        if (!updatedIntegrations.has(integration.integrationId)) {
          try {
            await removeWebhookFromIntegration({
              variables: {
                integrationId: integration.integrationId,
                webhookConfigId: webhookId,
              },
            });
          } catch (error) {
            let errorMessage = `Unable to remove Webhook Config from ${integration.name}`;
            if (removeWebhookFromIntegrationError) {
              errorMessage += `: ${removeWebhookFromIntegrationError.message}`;
            } else if (error instanceof Error) {
              errorMessage += `: ${error.message}`;
            }
            allErrors.push(errorMessage);
          }
        }
      }
    } catch (error) {
      let errorMessage = `Unable to update Webhook Config to integrations associations`;
      if (error instanceof Error) {
        errorMessage += `: ${error.message}`;
      }
      allErrors.push(errorMessage);
    }

    resetForm();

    if (allErrors.length > 0) {
      props.onError(true, allErrors.join(', '));
      return;
    }

    props.onCompleted({
      data: data,
      message: `${data?.updateWebhookConfig?.name} Webhook Config updated successfully`,
    });
  };

  const handleDeleteCompleted = (data: any) => {
    props.onCompleted({ data, message: `${props.webhookData.name} Webhook Config successfully deleted!` });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEventTypeSelect = (event: React.SyntheticEvent, value: string[]) => {
    setFormData({
      ...formData,
      eventTypes: value,
    });
  };

  const handleIntegrationSelect = (event: React.SyntheticEvent, value: SimpleIntegrationData[]) => {
    setFormData({
      ...formData,
      integrations: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await updateWebhookConfig({
          variables: {
            webhookConfigId: props.webhookData.webhookConfigId,
            name: trimmedData.name,
            description: trimmedData.description,
            endpoint: trimmedData.endpoint,
            eventTypes: getWebhookEventTypeValues(formData.eventTypes),
          },
          onCompleted: (data) => handleIntegrationAssociation(data),
        });
      } catch (error) {
        let errorMessage = `Failed to update Webhook Config`;
        if (updateWebhookConfigError) {
          errorMessage = `${errorMessage}: ${updateWebhookConfigError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      props.onError(false, '');
      await deleteWebhookConfig({
        variables: { webhookConfigId: props.webhookData.webhookConfigId },
        onCompleted: (data) => handleDeleteCompleted(data),
      });
    } catch (error) {
      let errorMessage = `Failed to delete ${props.webhookData.name} Webhook Config`;
      if (deleteWebhookConfigError) {
        errorMessage = `${errorMessage}: ${deleteWebhookConfigError.message}`;
      } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
      props.onError(true, errorMessage);
    }
  };

  const isFormIncomplete: boolean =
    formData.name.trim() === '' ||
    formData.description.trim() === '' ||
    formData.endpoint.trim() === '' ||
    formData.eventTypes.length === 0 ||
    formData.integrations.length === 0;

  return (
    <form id={'form-edit-webhook'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          fullWidth
          autoComplete="off"
          id="input-edit-webhook-name"
          label="Name"
          name="name"
          type="text"
          onChange={handleInputChange}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}

        <StyledTextField
          autoComplete="off"
          id="input-edit-webhook-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <StyledTextField
          required
          autoComplete="off"
          id="input-edit-webhook-endpoint"
          label="Endpoint"
          name="endpoint"
          type="text"
          onChange={handleInputChange}
          value={formData.endpoint}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.endpoint}
        />
        {errors.endpoint && (
          <FormHelperText id="endpoint-helper" error={!!errors.endpoint}>
            {errors.endpoint}
          </FormHelperText>
        )}

        <Stack direction={'row'} alignItems={'center'}>
          <Autocomplete
            sx={{ maxWidth: '350px' }}
            multiple
            value={getWebhookEventTypeValues(formData.eventTypes)}
            id="input-edit-webhook-event-types"
            options={Object.values(WebhookEventType)}
            disableCloseOnSelect
            onChange={handleEventTypeSelect}
            getOptionLabel={(option) => option.replace(/_/g, ' ')}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.replace(/_/g, ' ')}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                required
                inputProps={{
                  ...params.inputProps,
                  required: formData.eventTypes.length === 0,
                  readOnly: true,
                }}
                label="Event Types"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <StyledChip size={'small'} {...getTagProps({ index })} label={option.replace(/_/g, ' ')} />
              ));
            }}
            data-testid="event-type-autocomplete"
          />
          <InfoToolTip
            id={'tooltip-edit-webhook-event-types'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={<Typography variant={'body2'}>Select the types of events to receive.</Typography>}
          />
        </Stack>

        <Autocomplete
          sx={{ maxWidth: '350px' }}
          multiple
          value={formData.integrations}
          id="input-edit-webhook-integrations"
          options={props.integrations}
          disableCloseOnSelect
          onChange={handleIntegrationSelect}
          isOptionEqualToValue={(option, value) => option.integrationId === value.integrationId}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              required
              inputProps={{
                ...params.inputProps,
                required: formData.integrations.length === 0,
                readOnly: true,
              }}
              label="Integrations"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <StyledChip size={'small'} {...getTagProps({ index })} label={option.name} />
            ));
          }}
          data-testid="integrations-autocomplete"
        />

        <SubmitButtonWithLoader
          id={'btn-edit-webhook-submit'}
          disabled={
            isFormIncomplete ||
            updatingWebhookConfig ||
            deletingWebhookConfig ||
            addingWebhookToIntegration ||
            removingWebhookFromIntegration
          }
          label={'Save Changes'}
          loading={updatingWebhookConfig || addingWebhookToIntegration || removingWebhookFromIntegration}
        />

        <DeleteButton
          id={'btn-delete-webhook'}
          resourceName={props.webhookData.name}
          deleteButtonLabel={'Delete Webhook Config'}
          deleteButtonOnClick={handleDeleteSubmit}
          deleteButtonDisabled={
            deletingWebhookConfig ||
            updatingWebhookConfig ||
            addingWebhookToIntegration ||
            removingWebhookFromIntegration
          }
          loading={deletingWebhookConfig}
        />
      </FormControl>
    </form>
  );
};

export default EditWebhookConfigForm;
