import Typography from '@mui/material/Typography';
import React from 'react';

const UserScopeClientsInfo = () => {
  return (
    <Typography variant={'body2'}>
      User scope clients are used to configure your user authorization implementation
      <br />
      with the Allegion API.
    </Typography>
  );
};

export default UserScopeClientsInfo;
