import { Autocomplete, Checkbox, FormControl, FormHelperText } from '@mui/material';
import { StyledChip, StyledTextField } from '../../../../common/Theme';
import React, { useState } from 'react';
import {
  addM2MCredsToIntegrationGql,
  deleteAppClientGql,
  removeM2MCredsFromIntegrationGql,
  updateM2MCredsGql,
} from '../../../../../helpers/gqlQueries';
import { useMutation } from '@apollo/client';
import { validateAppClientDescription, validateAppClientName } from '@yonomi/util-validators';
import { trimObjectValues } from '../../../../../helpers/dataUtils';
import DeleteButton from '../../../../common/DeleteButton';
import SubmitButtonWithLoader from '../../../../common/SubmitButtonWithLoader';
import { M2MClientData, MutationResponse, SimpleIntegrationData } from '../../../../../models/DataTypes';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

const UPDATE_M2M_CLIENT = updateM2MCredsGql();
const DELETE_CLIENT = deleteAppClientGql();
const ADD_M2M_CLIENT_TO_INTEGRATION = addM2MCredsToIntegrationGql();
const REMOVE_M2M_CLIENT_FROM_INTEGRATION = removeM2MCredsFromIntegrationGql();

interface EditM2MClientFormProps {
  clientData: M2MClientData;
  integrations: SimpleIntegrationData[];
  onCompleted: (response: MutationResponse) => void;
  onError: (hasError: boolean, message: string) => void;
}

interface EditM2MClientFormData {
  name: string;
  description: string;
  integrations: SimpleIntegrationData[];
}

const EditM2MClientForm = (props: EditM2MClientFormProps) => {
  //GQL
  const [updateM2MClient, { error: updateM2MClientError, loading: updatingM2MClient }] = useMutation(UPDATE_M2M_CLIENT);
  const [deleteClient, { error: deleteClientError, loading: deletingClient }] = useMutation(DELETE_CLIENT);
  const [addM2mToIntegration, { error: addM2mToIntegrationError, loading: addingM2MClientToIntegration }] = useMutation(
    ADD_M2M_CLIENT_TO_INTEGRATION
  );
  const [
    removeM2mFromIntegration,
    { error: removeM2mFromIntegrationError, loading: removingM2MClientFromIntegration },
  ] = useMutation(REMOVE_M2M_CLIENT_FROM_INTEGRATION);

  //States
  const [formData, setFormData] = useState<EditM2MClientFormData>({
    name: props.clientData.name,
    description: props.clientData.description,
    integrations: props.clientData.integrations,
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  //Handlers
  const validateForm = (data: EditM2MClientFormData) => {
    let valid = true;
    const newErrors = {
      name: '',
      description: '',
    };

    try {
      validateAppClientName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Client Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    if (data.description) {
      try {
        validateAppClientDescription(data.description);
      } catch (error) {
        valid = false;
        let errorMessage = 'Invalid Client Description';
        if (error instanceof Error) errorMessage = error.message;
        newErrors.description = errorMessage;
      }
    }
    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      integrations: [],
    });
    setErrors({
      name: '',
      description: '',
    });
  };

  const handleIntegrationAssociation = async (data: any) => {
    const clientId: string = data?.updateAppClient?.clientId;
    const allErrors: string[] = [];

    const currentIntegrations = new Set(props.clientData.integrations.map(({ integrationId }) => integrationId));
    const updatedIntegrations = new Set(formData.integrations.map(({ integrationId }) => integrationId));

    try {
      //For any new integrations, add the m2m client to the integration
      for (const integration of formData.integrations) {
        if (!currentIntegrations.has(integration.integrationId)) {
          try {
            await addM2mToIntegration({
              variables: {
                integrationId: integration.integrationId,
                clientId: clientId,
              },
            });
          } catch (error) {
            let errorMessage = `Unable to add Machine to Machine Client to ${integration.name}`;
            if (addM2mToIntegrationError) {
              errorMessage += `: ${addM2mToIntegrationError.message}`;
            } else if (error instanceof Error) {
              errorMessage += `: ${error.message}`;
            }
            allErrors.push(errorMessage);
          }
        }
      }
      //For any integrations that were removed, remove the m2m client from the integration
      for (const integration of props.clientData.integrations) {
        if (!updatedIntegrations.has(integration.integrationId)) {
          try {
            await removeM2mFromIntegration({
              variables: {
                integrationId: integration.integrationId,
                clientId: clientId,
              },
            });
          } catch (error) {
            let errorMessage = `Unable to remove Machine to Machine Client from ${integration.name}`;
            if (removeM2mFromIntegrationError) {
              errorMessage += `: ${removeM2mFromIntegrationError.message}`;
            } else if (error instanceof Error) {
              errorMessage += `: ${error.message}`;
            }
            allErrors.push(errorMessage);
          }
        }
      }
    } catch (error) {
      let errorMessage = `Unable to update Machine to Machine Client to integrations associations`;
      if (error instanceof Error) {
        errorMessage += `: ${error.message}`;
      }
      allErrors.push(errorMessage);
    }

    resetForm();

    if (allErrors.length > 0) {
      props.onError(true, allErrors.join(', '));
      return;
    }

    props.onCompleted({
      data: data,
      message: `${data?.updateAppClient?.name} Machine to Machine Client successfully updated!`,
    });
  };

  const handleDeleteCompleted = (data: any) => {
    props.onCompleted({ data, message: `${props.clientData.name} Machine to Machine Client successfully deleted!` });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleIntegrationSelect = (event: React.SyntheticEvent, value: SimpleIntegrationData[]) => {
    setFormData({
      ...formData,
      integrations: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await updateM2MClient({
          variables: {
            clientId: props.clientData.clientId,
            name: trimmedData.name,
            description: trimmedData.description,
          },
          onCompleted: (data) => handleIntegrationAssociation(data),
        });
      } catch (error) {
        let errorMessage = `Failed to update Machine to Machine Client`;
        if (updateM2MClientError) {
          errorMessage = `${errorMessage}: ${updateM2MClientError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      props.onError(false, '');
      await deleteClient({
        variables: { clientId: props.clientData.clientId },
        onCompleted: (data) => handleDeleteCompleted(data),
      });
    } catch (error) {
      let errorMessage = `Failed to delete ${props.clientData.name} Machine to Machine Client`;
      if (deleteClientError) {
        errorMessage = `${errorMessage}: ${deleteClientError.message}`;
      } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
      props.onError(true, errorMessage);
    }
  };

  const isFormIncomplete: boolean = formData.name.trim() === '' || formData.integrations.length === 0;

  return (
    <form id={'form-edit-m2m-creds'} onSubmit={handleSubmit}>
      <FormControl>
        <StyledTextField
          required
          autoFocus
          fullWidth
          autoComplete="off"
          id="input-edit-m2m-creds-name"
          label="Name"
          name="name"
          type="text"
          onChange={handleInputChange}
          value={formData.name}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.name}
        />
        {errors.name && (
          <FormHelperText id="name-helper" error={!!errors.name}>
            {errors.name}
          </FormHelperText>
        )}

        <StyledTextField
          autoComplete="off"
          id="dinput-edit-m2m-creds-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <Autocomplete
          sx={{ maxWidth: '350px' }}
          multiple
          value={formData.integrations}
          id="input-edit-m2m-creds-integrations"
          options={props.integrations}
          disableCloseOnSelect
          onChange={handleIntegrationSelect}
          isOptionEqualToValue={(option, value) => option.integrationId === value.integrationId}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              required
              inputProps={{
                ...params.inputProps,
                required: formData.integrations.length === 0,
                readOnly: true,
              }}
              label="Integrations"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <StyledChip size={'small'} {...getTagProps({ index })} label={option.name} />
            ));
          }}
          data-testid="integrations-autocomplete"
        />

        <SubmitButtonWithLoader
          id={'btn-edit-m2m-creds'}
          disabled={
            isFormIncomplete ||
            updatingM2MClient ||
            deletingClient ||
            addingM2MClientToIntegration ||
            removingM2MClientFromIntegration
          }
          label={'Save Changes'}
          loading={updatingM2MClient || addingM2MClientToIntegration || removingM2MClientFromIntegration}
        />

        <DeleteButton
          id={'btn-delete-m2m-creds'}
          resourceName={props.clientData.name}
          deleteButtonLabel={'Delete Machine to Machine Client'}
          deleteButtonOnClick={handleDeleteSubmit}
          deleteButtonDisabled={
            deletingClient || updatingM2MClient || addingM2MClientToIntegration || removingM2MClientFromIntegration
          }
          loading={deletingClient}
        />
      </FormControl>
    </form>
  );
};

export default EditM2MClientForm;
