import React from 'react';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import { useTheme } from '@mui/material';

interface SnackBarProps extends SnackbarProps {
  open: boolean;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  message: string;
  severity: AlertColor;
}

export interface SnackBarState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const SnackBar = ({ open, handleClose, message, severity, ...otherProps }: SnackBarProps) => {
  const theme = useTheme();
  return (
    <Snackbar
      open={open}
      autoHideDuration={severity === 'error' ? null : 8000}
      onClose={handleClose}
      message={message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{
        width: '80%',
        maxWidth: 600,
      }}
      {...otherProps}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{
          width: '100%',
          backgroundColor: theme.palette[severity].main,
          color: '#000000',
          '& .MuiAlert-icon': {
            color: '#000000',
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
