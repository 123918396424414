import { useTheme } from '@mui/material';
import * as React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import Typography from '@mui/material/Typography';
import InfoToolTip from '../InfoToolTip';

type ComparatorFunction<T> = (a: T, b: T) => number;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T | ComparatorFunction<T>): number {
  if (typeof orderBy === 'function') {
    return orderBy(a, b);
  }

  const getValue = (item: T) => {
    const value = item[orderBy];
    if (typeof value === 'string') {
      return value.toLowerCase();
    } else if (Array.isArray(value)) {
      return value[0];
    } else {
      return value;
    }
  };

  const aValue = getValue(a);
  const bValue = getValue(b);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(order: Order, orderBy: keyof T | ComparatorFunction<T>): ComparatorFunction<T> {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export type Order = 'asc' | 'desc';

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    if (Array.isArray(a[0]) && Array.isArray(b[0])) {
      // Compare arrays element-wise
      for (let i = 0; i < Math.min(a[0].length, b[0].length); i++) {
        if (a[0][i] !== b[0][i]) {
          return a[0][i] - b[0][i];
        }
      }
    }

    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell {
  id: any;
  label: string;
  info?: string;
}

interface SortableTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

const SortableTableHead = (props: SortableTableHeadProps) => {
  const theme = useTheme();
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: theme.palette.grey[900] }}>
        {headCells.map((headCell) => (
          <TableCell
            variant={'head'}
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ height: '57px', padding: '8px 16px', fontWeight: 700 }}
          >
            <TableSortLabel
              id={`th-${headCell.id}`}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                '&.Mui-active .MuiTableSortLabel-icon': {
                  color: `${theme.palette.text.primary}`,
                },

                '&:hover': {
                  color: theme.palette.primary.main,
                  '& .MuiTableSortLabel-icon': {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            >
              {headCell.label}
              {headCell.info && (
                <>
                  <InfoToolTip
                    id={`tooltip-th-${headCell.id}`}
                    iconSx={{ width: '18px', height: '18px' }}
                    placement="right"
                    title={<Typography variant={'body2'}>{headCell.info}</Typography>}
                  />
                  <Box component="span" sx={visuallyHidden} data-testid={`info-tooltip-${headCell.id}`} />
                </>
              )}
              {orderBy === headCell.id ? (
                <Box id={`icon-sorted-by-${headCell.id}`} component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align={'left'} padding={'normal'}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SortableTableHead;
