import Box from '@mui/material/Box';
import { FederationConfigData, MutationResponse } from '../../../../models/DataTypes';
import * as React from 'react';
import { MutableRefObject } from 'react';
import { Button, useTheme } from '@mui/material';
import { useRefetch } from '../../../../RefetchContext';
import SortableTableHead, {
  getComparator,
  HeadCell,
  Order,
  stableSort,
} from '../../../common/tables/SortableTableHead';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Loader from '../../../common/Loader';
import { formatDate } from '../../../../helpers/dataUtils';
import { Edit } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import ActionModal from '../../../common/ActionModal';
import SelectedItemTable from '../../../common/tables/SelectedItemTable';
import EditFederationConfigForm from '../forms/EditFederationConfigForm';
import { getIdFragment } from '../../../../helpers/testUtils';
import CopyButton from '../../../common/CopyButton';
import PaginationFooter from '../../../common/PaginationFooter';
import { StyledTableContainer } from '../../../common/Theme';

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'federationClientId',
    label: 'Federation Client ID',
  },
  {
    id: 'createdAt',
    label: 'Date Created',
  },
];

const DEFAULT_ROWS_PER_PAGE = 5;

interface DataTableProps {
  rows: FederationConfigData[];
  fetchMore: () => void;
  onError: (hasError: boolean, message: string) => void;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
  loading: boolean;
}

const FederationConfigTable = ({ rows, fetchMore, onCompleted, onError, loading }: DataTableProps) => {
  const theme = useTheme();

  //GQL
  const { refetchByKey } = useRefetch();
  const refetchFederationConfigs = () => refetchByKey('listFederationConfigs');

  //States
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof FederationConfigData>('createdAt');
  const [selected, setSelected] = React.useState<number>();
  const [openViewConfigModal, setOpenViewConfigModal] = React.useState(false);
  const [openEditConfigModal, setOpenEditConfigModal] = React.useState(false);
  const [selectedConfig, setSelectedConfig] = React.useState<FederationConfigData>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleLastPage = () => {
    fetchMore();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Handlers
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FederationConfigData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    if (selected === id) {
      setSelected(-1);
      return;
    }
    setSelected(id);
  };

  const handleViewConfigModalOpen = (selectedConfig: FederationConfigData) => {
    setSelectedConfig(selectedConfig);
    setOpenViewConfigModal(true);
  };

  const handleViewConfigModalClose = () => {
    setOpenViewConfigModal(false);
  };

  const handleEditConfigModalOpen = (selectedConfig: FederationConfigData) => {
    setSelectedConfig(selectedConfig);
    setOpenEditConfigModal(true);
  };

  const handleEditConfigModalClose = () => {
    setOpenEditConfigModal(false);
  };

  const handleComplete = (response: MutationResponse) => {
    refetchFederationConfigs();
    handleEditConfigModalClose();
    handleViewConfigModalClose();
    onCompleted?.current(response);
  };

  const isSelected = (id: number) => selected === id;

  const sortedRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)), [order, orderBy, rows]);
  const slicedRows =
    rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, backgroundColor: theme.palette.background.default }}>
        <StyledTableContainer>
          <Table id={'table-federation-configs'} sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody id={`tbody-fed-configs`}>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1} align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid="loader">
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : rows.length > 0 ? (
                slicedRows.map((row, index) => {
                  const isItemSelected = isSelected(row.index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      id={`tr-fed-config-${getIdFragment(row.name)}-${row.index}`}
                      hover
                      onClick={(event) => handleClick(event, row.index)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="normal">
                        <Button
                          id={`btn-view-fed-config-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleViewConfigModalOpen(row)}
                          sx={{ padding: '0px', margin: '0px', textTransform: 'none' }}
                        >
                          {row.name}
                        </Button>
                      </TableCell>
                      <TableCell id={`td-fed-config-description-${getIdFragment(row.name)}-${row.index}`}>
                        {row.description}
                      </TableCell>
                      <TableCell id={`td-fed-config-federation-client-id-${getIdFragment(row.name)}-${row.index}`}>
                        {row.federationClientId}
                        <CopyButton
                          id={`btn-copy-fed-config-client-id-${getIdFragment(row.name)}-${index}`}
                          data={row.federationClientId}
                        />
                      </TableCell>
                      <TableCell id={`td-fed-config-created-at-${getIdFragment(row.name)}-${row.index}`}>
                        {formatDate(row.createdAt)}
                      </TableCell>

                      <TableCell>
                        <Button
                          id={`btn-edit-fed-config-${getIdFragment(row.name)}-${row.index}`}
                          onClick={() => handleEditConfigModalOpen(row)}
                          sx={{
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0px',
                            padding: '0px',
                          }}
                        >
                          <Edit />
                          <Typography variant={'button'} sx={{ marginLeft: '5px' }}>
                            Edit
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow id={`tr-fed-config-none`}>
                  <TableCell id={`td-fed-config-none`} colSpan={headCells.length + 1} align="center">
                    You currently do not have any federation configs for this dev group
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <PaginationFooter
            id="pagination-federation-configs"
            count={sortedRows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            pageChangeHandler={handleChangePage}
            handleLastPage={handleLastPage}
            rowsPerPageChangeHandler={handleChangeRowsPerPage}
          />
        </StyledTableContainer>
      </Paper>
      <ActionModal
        id={'view-fed-config'}
        open={openViewConfigModal}
        handleClose={handleViewConfigModalClose}
        subHeader={`Federation Config`}
        title={selectedConfig?.name}
        dialogContent={
          selectedConfig && (
            <SelectedItemTable
              selected={selectedConfig}
              onEditOpen={handleEditConfigModalOpen}
              excludedKeys={['__typename', 'index', 'devGroupId', 'name']}
              clipboardEnabledKeys={[
                'federationDiscoveryUrl',
                'federationClientId',
                'callbackUrl',
                'connectionId',
                'fedConfigId',
              ]}
            />
          )
        }
      />
      <ActionModal
        id={'edit-fed-config'}
        open={openEditConfigModal}
        handleClose={handleEditConfigModalClose}
        title={`Edit Federation Config`}
        dialogContent={
          selectedConfig && (
            <EditFederationConfigForm
              federationConfig={selectedConfig}
              onCompleted={handleComplete}
              onError={onError}
            />
          )
        }
      />
    </Box>
  );
};

export default FederationConfigTable;
