import { NavListItemButton } from '../common/Theme';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ExpandLess, ExpandMore, Spoke } from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import List from '@mui/material/List';
import React, { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

interface DevResourceGroupMenuProps {
  name: string;
  devGroupId: string;
  organizationId: string;
  hasFedConfigMgmtAccess: boolean;
}

const DevResourceGroupMenu = ({
  name,
  devGroupId,
  organizationId,
  hasFedConfigMgmtAccess,
}: DevResourceGroupMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openGroupMenu, setOpenGroupMenu] = React.useState(false);
  const [openClientsMenu, setOpenClientsMenu] = React.useState(true);

  const handleNav = (section: string) => {
    navigate({
      pathname: `/${section}`,
      search: createSearchParams({
        devGroupId,
        devGroupName: name,
        organizationId,
      }).toString(),
    });
  };

  const handleToggleGroupMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setOpenGroupMenu(!openGroupMenu);
  };

  const handleToggleClientsMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setOpenClientsMenu(!openClientsMenu);
  };

  const isSelected = (path: string): boolean => {
    return location.pathname === `/${path}`;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (
      (location.pathname === '/developer' ||
        location.pathname === '/integrations' ||
        location.pathname === '/app-clients' ||
        location.pathname === '/fed-config' ||
        location.pathname === '/webhooks') &&
      searchParams.get('devGroupId') === devGroupId
    ) {
      setOpenGroupMenu(true);
    } else {
      setOpenGroupMenu(false);
    }
  }, [devGroupId, location]);

  return (
    <>
      <NavListItemButton
        id={`btn-dev-group-menu-header-${name}`}
        onClick={() => handleNav('developer')}
        selected={openGroupMenu}
      >
        <ListItemIcon>
          <Spoke />
        </ListItemIcon>
        <ListItemText primary={name} primaryTypographyProps={{ variant: 'body1' }} />
        {openGroupMenu ? (
          <ExpandLess onClick={handleToggleGroupMenu} data-testid="collapse-dev-group-menu" />
        ) : (
          <ExpandMore onClick={handleToggleGroupMenu} data-testid="expand-dev-group-menu" />
        )}
      </NavListItemButton>
      <Collapse in={openGroupMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavListItemButton
            id={`btn-dev-group-menu-integrations-${name}`}
            sx={{ pl: 4 }}
            selected={isSelected('integrations')}
            onClick={() => handleNav('integrations')}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Integrations" primaryTypographyProps={{ variant: 'body2' }} />
          </NavListItemButton>

          <NavListItemButton
            id={`btn-dev-group-menu-app-clients-${name}`}
            sx={{ pl: '60px' }}
            onClick={() => handleNav('app-clients')}
            selected={isSelected('app-clients')}
          >
            <ListItemText primary={'Application Clients'} primaryTypographyProps={{ variant: 'body2' }} />
            {openClientsMenu ? (
              <ExpandLess onClick={handleToggleClientsMenu} />
            ) : (
              <ExpandMore onClick={handleToggleClientsMenu} />
            )}
          </NavListItemButton>
          <Collapse in={openClientsMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavListItemButton
                id={`btn-dev-group-menu-m2m-${name}`}
                sx={{ pl: 4 }}
                selected={isSelected('app-clients')}
                onClick={() => handleNav('app-clients')}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  sx={{ paddingLeft: '10px' }}
                  primary="Machine to Machine Clients"
                  primaryTypographyProps={{ variant: 'body2', sx: { opacity: isSelected('app-clients') ? 1 : 0.6 } }}
                />
              </NavListItemButton>
              <NavListItemButton
                id={`btn-dev-group-menu-client-creds-${name}`}
                sx={{ pl: 4 }}
                selected={isSelected('app-clients')}
                onClick={() => handleNav('app-clients')}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  sx={{ paddingLeft: '10px' }}
                  primary="User Scope Clients"
                  primaryTypographyProps={{ variant: 'body2', sx: { opacity: isSelected('app-clients') ? 1 : 0.6 } }}
                />
              </NavListItemButton>
            </List>
          </Collapse>

          <NavListItemButton
            id={`btn-dev-group-menu-webhooks-${name}`}
            sx={{ pl: 4 }}
            selected={isSelected('webhooks')}
            onClick={() => handleNav('webhooks')}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Webhooks" primaryTypographyProps={{ variant: 'body2' }} />
          </NavListItemButton>

          {hasFedConfigMgmtAccess ? (
            <NavListItemButton
              id={`btn-dev-group-menu-fed-config-${name}`}
              sx={{ pl: 4 }}
              selected={isSelected('fed-config')}
              onClick={() => handleNav('fed-config')}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Federation Config" primaryTypographyProps={{ variant: 'body2' }} />
            </NavListItemButton>
          ) : null}
        </List>
      </Collapse>
    </>
  );
};

export default DevResourceGroupMenu;
