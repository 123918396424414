import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Button, useTheme } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import InfoToolTip from '../../common/InfoToolTip';
import Loader from '../../common/Loader';

interface sectionInfo {
  sectionName: string;
  path: string;
}

const sections: Record<string, sectionInfo> = {
  integrations: {
    sectionName: 'Integrations',
    path: 'integrations',
  },
  'application-clients': {
    sectionName: 'Application Clients',
    path: 'app-clients',
  },
  'federation-configs': {
    sectionName: 'Fed Configs',
    path: 'fed-config',
  },
  webhooks: {
    sectionName: 'Webhooks',
    path: 'webhooks',
  },
};
type Section = keyof typeof sections;

const getPath = (idName: Section) => sections[idName].path;

interface DevGroupMetricBoxProps {
  sectionName: string;
  devGroupId: string;
  devGroupName: string;
  organizationId: string;
  topRightItem?: React.ReactNode;
  loading: boolean;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  restrictedAccess?: boolean;
  id?: string;
  sx?: React.CSSProperties;
}

const DevGroupMetricBox: React.FC<DevGroupMetricBoxProps> = ({
  sectionName,
  devGroupId,
  devGroupName,
  organizationId,
  topRightItem,
  loading,
  tooltip,
  children,
  restrictedAccess = false,
  sx,
}: DevGroupMetricBoxProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const idName = sectionName.toLowerCase().replace(/\s+/g, '-');

  const hasTopRightItem = !!topRightItem;

  const handleNav = (section: string) => {
    navigate({
      pathname: `/${section}`,
      search: createSearchParams({
        devGroupId,
        devGroupName: devGroupName,
        organizationId: organizationId,
      }).toString(),
    });
  };

  return (
    <Box
      component={'div'}
      id={`panel-dev-group-metrics-${idName}`}
      data-testid={`panel-dev-group-metrics-${idName}`}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: '600px',
        maxHeight: '184px',
        paddingX: '24px',
        paddingY: '15px',
        backgroundColor: theme.palette.grey[900],
        borderRadius: '8px',
        marginBottom: '24px',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        {/* Title goes here */}
        <Box
          id={`label-dev-group-metrics-${idName}-title`}
          data-testid={`label-dev-group-metrics-${idName}-title`}
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" align="left" paragraph sx={{ marginBottom: 0, opacity: restrictedAccess ? 0.6 : 1 }}>
            {sectionName}
          </Typography>

          {tooltip && (
            <InfoToolTip
              id={`tooltip-dev-group-metrics-${idName}`}
              placement="right"
              title={tooltip}
              sx={{ opacity: restrictedAccess ? 0.6 : 1 }}
            />
          )}
        </Box>

        {/* Top-right Item count */}
        <Box
          id={`label-dev-group-metrics-${idName}-item-count`}
          data-testid={`label-dev-group-metrics-${idName}-item-count`}
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          {loading ? (
            <Box
              id={`loader-dev-group-metrics-${idName}-item-count`}
              data-testid={`loader-dev-group-metrics-${idName}-item-count`}
            >
              <Loader />
            </Box>
          ) : hasTopRightItem ? (
            topRightItem
          ) : (
            <Typography variant="body2" align="right" sx={{ color: theme.palette.grey[400] }}>
              You currently do not have any {sectionName} assigned to this dev resource group.
            </Typography>
          )}
        </Box>
      </Box>

      {/* Body content */}
      <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '4px', justifyContent: 'space-evenly' }}>
        {children}
      </Box>

      {/* Bottom-right link */}
      {!restrictedAccess ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '8px',
          }}
        >
          <Button
            id={`btn-dev-group-metrics-go-to-${idName}-section`}
            data-testid={`btn-dev-group-metrics-go-to-${idName}-section`}
            sx={{ textTransform: 'none', color: theme.palette.text.primary, display: 'flex', alignItems: 'center' }}
            onClick={() => handleNav(getPath(idName))}
          >
            <Typography color="primary.main" variant="button">
              Go to {sectionName}
            </Typography>
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default DevGroupMetricBox;
